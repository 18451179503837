.companiesPageContainer {
    // margin-top: 7rem;
    padding-bottom: 3rem;
}


.nav-tabs .nav-link {
    font-size: 17px;
    font-weight: 500;
    color: gray !important;
}

.nav-tabs .nav-link.active {
    // border-bottom: 3px solid #007CE9 !important;
    font-weight: 700;
    // padding: 3px;
    color: black !important;

}

// .sectionsContainer{
//     display: flex;
// }

.leftSide {
    width: 45%;
    border-right: 2px solid gray;
    margin: 0px 10px;
    display: flex;
    justify-content: start;
    // padding: 20px;

    label {
        font-weight: 600;
        font-size: 18px;
    }

}



.checkbox {
    margin-left: 2rem;
    width: 15px;
    height: 15px;
    border: 1px solid blue;
}


.companies_card_wrapper {
    gap: 3rem;
}

.map_cardWrapper {
    width: 22%;
    height: 420px;
    //    transform: scale(0.8);
}

.cardWrapper {
    border: 1px solid gray;
    width: 100%;
    border-radius: 8px;
    gap: 2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);

    &:hover {
        transition: 0.4s;
        transform: scale(1.1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .cardText {
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;

        font-family: 'sansation';
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    p {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    hr {
        width: 90%;
        margin: 1rem auto;
    }


}

.pageMianheading {
    font-weight: bold;
    font-family: 'sansation';
}

.cardimg {
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    object-fit: contain;
    height: 205px;

}

.cardimgBlur {
    filter: blur(6px);
}

.company-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.company-card-desc {
    height: 4.3rem;
    // white-space: nowrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}


@media (max-width: 500px) {
    .cardWrapper {
        width: 90%;

    }

    .map_cardWrapper {
        width: 100%;
    }

    .sectionsContainer {
        display: flex;
        flex-direction: column;
    }

    .leftSide {
        width: 50%;
        border: none;
        margin-bottom: 2rem;
        border-bottom: 2px solid silver;
    }

}

@media (min-width:500px) and (max-width: 768px) {
    .cardWrapper {
        border: 1px solid gray;
        width: 65%;

    }

    .map_cardWrapper {
        width: 100%;
    }

    .leftSide {
        width: 35%;
        border: none;
        margin-bottom: 2rem;
        border-right: 2px solid silver;

        label {
            font-size: 16px;
            font-weight: 600;
        }
    }


    .checkbox {
        margin-left: 1rem;
        width: 15px;
        height: 15px;
        border: 1px solid blue;
    }


}
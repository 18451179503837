@import '../../partails/Common/Utillies_Color';

.slick-dots li.slick-active button {
    &::before {
        color: $primary-color !important;
    }
}

.slick-dots {
    font-size: 2rem !important;
}

.img_about {
    object-fit: cover !important;
    backdrop-filter: blur(60px) brightness(200%);
    width: 300px !important;
    max-width: 100%;
    aspect-ratio: 3/2;
}

.about_choose {
    min-height: 25rem;
}

.HowCard_icon i {
    font-size: 2.5rem;
    color: $primary-color;
    background-color: $text-grey;
    padding: 1.5rem;
    border-radius: 50%;
}

.Posting_ads_wrapper {
    background-color: $primary-color;
}

.upper_container {

    padding-top: 1rem;
    padding-bottom: 1rem;
    // background-color: rgb(252, 250, 250);
    border-radius: 100%;
}

.upper_container p {
    margin-bottom: 0;
}

.chose_heading {
    font-size: 1.2rem;
    font-weight: 600;
}

.team_section {
    background-color: #F7F7F7;
    border: 1px solid rgb(214, 208, 208);
    border-radius: 10px;
    width: 24rem;
}

.team_section .card {
    background-color: #F7F7F7;
}


.aboutUsBg {
    // background: url("../../images/paymentBg.jpg") no-repeat top, linear-gradient(180deg, rgba(0, 19, 42) 33.84%, #00132A 97.79%);
    border-radius: 0px;

    .abt_bnr {
        width: 100%;
        position: relative;
        height: auto;
        min-height: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero_inner {
        p {
            font-size: 20px;

            @media (min-width: 1800px) {
                font-size: 22px;
            }

            @media (max-width: 992px) {
                font-size: 18px;
            }
        }
    }

    // +.second_footer {
    //     background-color: #00132A;
    // }
}

@media(max-width: 550px) {
    .img_about {
        object-fit: cover !important;
    }
}

@media(max-width : 550px) {

    .updateModalP {
        font-size: 0.8rem;
    }

    .update_form_level_two .form-check-label {
        font-size: 0.8rem;

    }

}



@media(max-width : 450px) {


    .fade.stepmodal.modal.show {

        width: 103%;
        margin: 0;
        padding: 0 !important;
    }

    .modal-dialog .modal-lg {
        margin: 0;
    }
}


@media(max-width : 350px) {
    .signatureBtn {
        font-size: 0.8rem !important;
    }


}
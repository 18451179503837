.form-center {
    height: calc(100vh - 80px);
    width: 100%;
}

.btr {

    border-top-right-radius: 5px !important;
}


.btl {

    border-top-left-radius: 5px !important;
}

.btn-dark {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}


.btn-dark:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.nav-pills {

    display: table !important;
    width: 100%;
}

.nav-pills .nav-link {
    border-radius: 0px;
    border-bottom: 1px solid #0d6efd40;
    margin: 0;

}

.nav-pills .nav-item {
    display: table-cell;
    background: #0d6efd2e;
    padding: 0;
}

.form {

    padding: 10px;
    height: 300px;
}

.form input {

    margin-bottom: 12px;
    border-radius: 3px;
}


.form input:focus {

    box-shadow: none;
}


.form button {

    margin-top: 20px;
}


.signUpMainContent {
    // border: 1px solid white;
    // background-color: white;
    border-radius: 20px;
    width: 100%;
    margin-top: 7rem;

}

.signUpMainContent .tab-content {
    padding: 2rem;
    background-color: white;
    // overflow-y: scroll;
    height: auto;

}

.companyCornerRadius~.tab-content {
    border-radius: 0px 7px 7px 7px;

}

.userCornerRadius~.tab-content {
    border-radius: 7px 0px 7px 7px;

}


.signUpMainContent ul {
    border: none
}



.zipInput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.zipInput input[type=number] {
    -moz-appearance: textfield;
}






@media (min-width : 1440px) {
    .signUpMainContent .tab-content {
        padding: 2rem;
        background-color: white;
        // overflow: scroll;
        height: auto;

    }
}

@media (min-width : 992px) {
    .signUpMainContent .nav-tabs .nav-link.active {

        padding: 20px 0px;
    }
}

@media (max-width : 600px) {
    .signUpMainContent .nav-tabs .nav-link {
        font-size: .8rem !important;
    }


}

.eyeBtn {
    height: 58px;
    position: relative;
    top: 0px;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid gray !important;
    border-radius: 0px !important;
}

.nav-tabs .nav-item button {
    width: 100%;
    margin-left: 0 !important;
}

.nav-tabs .nav-item {
    width: 50%;
}

.signUpMainContent .nav-tabs .nav-link.active {
    color: navy !important;
    font-family: 'sansation';
    font-weight: bold;
    // padding: 20px 0px;
}

.signUpMainContent .nav-tabs .nav-link {
    color: white !important;
    font-weight: bold;
    padding: 20px 0px;
    font-family: 'sansation';
    border: none;
}

.hide_increament_button input[type=tel] {
    border: none;
    outline: none
}

.stepmodal .btn-close {
    position: absolute;
    right: 10px;
    top: 20px;
}

.signUpMainContent .tab-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    // border-radius: 10px;
}

.signUpMainContent .tab-content::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

.signUpMainContent .tab-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
            left bottom,
            left top,
            color-stop(0.44, rgb(122, 153, 217)),
            color-stop(0.72, rgb(73, 125, 189)),
            color-stop(0.86, rgb(28, 58, 148)));
}


.hell {
    display: inline-block;
    margin-bottom: 0;
    transform-origin: 0% 0%;
    position: sticky;
    top: 0;
}

// input:not([type=button]):not([type=password]):not([type=submit])...

#SignUpForm input[type=checkbox] {
    height: 20px;
    width: 20px;
    margin-right: 5px;

}

#SignUpForm input:not(.PhoneInputInput, .css-qbdosj-Input input):not([type=checkbox]),
.phnNoInput,
textarea {
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    border-radius: 0;
    margin-bottom: 0px;
    font-size: 15px;
    padding-bottom: 0.225rem !important;
    border-radius: 0 !important;
    min-height: auto !important;
}

#SignUpForm label {
    font-size: 15px !important;
    z-index: 0 !important;
}

#SignUpForm .PhoneInputInput::placeholder {
    font-size: 15px !important;
}

.login_inp {
    border: none !important;
    border-bottom: 1px solid gray !important;
    border-radius: 0 !important;
    outline: none !important;
}

.pri_cnt_form {
    position: relative;
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: .3s opacity ease;
    overflow: visible;
}

.hidefrm {
    visibility: hidden;
    opacity: 0;
    transition: .3s opacity ease;
    height: 0px;
    overflow: hidden;
}

.bckbtn {
    position: absolute;
    top: 10px;
    left: -55px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}


.signUpSelect .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000 !important;
    color: white;
    border-radius: 7px !important;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
    color: white;
}

#SignUpForm input:not(.PhoneInputInput, .css-qbdosj-Input input):focus {
    border-bottom: 1px solid #1890ff !important;
    outline: none;
    box-shadow: none !important;

}

.rtsel {
    font-size: 15px !important;
}

.rtsel .css-6di5oj-control {
    border-color: gray !important;
    border-width: 0 0 1px 0 !important;
    border-radius: 0px !important;
    min-height: 48px !important;
}

.rtsel .css-1x768kb-control {
    min-height: 48px !important;
}

.rtsel .css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.rtsel .css-1jqq78o-placeholder {
    color: #333;
}

input:focus label {
    color: blue;
}

.signUpLogoSec {
    border-radius: 10px;
    background-color: rgba(130, 130, 130, 0.1);
    ;
    border: 1px solid lightgray;
    padding: 10px 5px 10px 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

}

.signUpLogoSec img {
    height: 40px;
    object-fit: contain;
}

[placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease;
    text-indent: -100%;
    opacity: 1;
}


.uploadCompanyLogo p {
    word-wrap: break-word !important;
}

.helpSection:hover {
    color: blue !important;
}

.forgot-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


//   FORMIK FORM STYLING

.frmsign {
    max-width: 800px;
    margin: 0px auto 0;
    padding-top: 80px;
    border-radius: 10px;
    color: #fff;
}

.frmmain {
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;

    @media(max-width: 768px) {
        padding: 20px;
    }
}

.tabsfrm {
    display: flex;
    align-items: stretch;
    gap: 20px 25px;

    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        min-height: 100%;
        text-align: center;
        color: #fff;
        font-size: 22px;
        // box-shadow: 0 0 10px #333;
        border-radius: 8px 8px 0 0;
        text-transform: capitalize;
        background-color: transparent;
        padding: 15px 10px;
        cursor: pointer;
        transition: .3s all ease;
        margin: 0;

        @media(max-width: 550px) {
            font-size: 16px;
        }

        &.act {
            background-color: #fff;
            color: #171717;
            transition: .3s all ease;
        }
    }
}


.frmlabel {
    display: block;
    color: #171717;
}

.frmbx {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #ddd;
    height: 45px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 7px 0 5px;
    background-color: #f7f7f7;
    align-items: center;

    &.input-error {
        border: 1px solid rgb(211, 0, 0);
    }

    input {
        width: calc(100% - 30px);
        border: none;
        background-color: #f7f7f7;
        height: 32px;
        color: #171717;

        &::placeholder {
            color: #333333;
        }
    }

    .eyebtnfrm {
        width: 25px;
        color: #171717;
    }
}

.frminp {
    display: block;
    width: 100%;
    border: 1px solid #ddd;
    height: 45px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 7px 0 5px;
    background-color: #f7f7f7;
    color: #171717;

    &.input-error {
        border: 1px solid rgb(211, 0, 0);
    }

    &::placeholder {
        color: #333333;
    }
}

.ermsg {
    color: rgb(211, 0, 0);
    min-height: 22px;
    font-size: 14px;
}

.frmsubmit {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
    height: 45px;
    border-radius: 5px;
    text-align: center;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    outline: none;
    margin: 20px auto 0;
    min-width: 160px;

    &.graylog {
        filter: grayscale(1);
        cursor: not-allowed;
    }
}
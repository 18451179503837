.main-body {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: black;
    // margin-top: 10rem;
}

.card {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
}

.head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    justify-content: space-between;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
    text-align: center;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col,
.gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}

.h-100 {
    height: 100% !important;
}

.shadow-none {
    box-shadow: none !important;
}


// ======================================== profile new scss =============================

.user_info_box {
    color: #fff;
    background-color: #171717;
    border: 1px solid #ccc;
    padding: 5px 0px 20px;
    border-radius: 20px;
    margin: 75px 10px 0 !important;
    max-width: 960px;

    .profilrAvatar {
        border-radius: 12px;

        .ant-avatar {
            width: 100% !important;
            height: auto !important;
            border-radius: 12px !important;
            max-width: 130px;

            >img {
                aspect-ratio: 1 / 1;
            }
        }
    }

    .profileNameDiv {
        h3 {
            color: #fff;
            margin-bottom: 10px;

            @media(max-width: 550px) {
                font-size: 20px;
            }
        }

        h5 {
            color: #ccc;

            @media(max-width: 550px) {
                font-size: 14px;
            }
        }
    }

    .profileAmountDivWrapper {
        justify-content: flex-start;
        align-items: end;
    }
}


.profilrAvatar {
    display: flex !important;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .ant-avatar {
        // width: 100% !important;
        max-width: 130px;
        height: auto !important;
        aspect-ratio: 1 / 1;

        >img {
            aspect-ratio: 1 / 1;
        }

        @media(max-width: 550px) {
            max-width: 70px;
        }
    }

}

.profileNameDiv h4 {
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: 'sansation';

    // text-align:center;
    @media(max-width: 550px) {
        font-size: 20px;
    }
}

.profileNameDiv h5 {
    color: gray;
    font-weight: 500;
    font-size: 1rem;
    font-family: 'sansation';
}

.profileAmountDivWrapper {
    display: flex;
    align-items: flex-end;
    padding-right: 1rem !important;
    justify-content: center;
    flex-direction: column;
}

.profileAmountDiv h5 {
    color: gray;
    font-weight: 500;
    font-family: 'sansation';

}


.profileAmountDiv h2 {
    color: #0370ce;
    font-weight: bold;
    font-family: 'sansation';


}

.profileDepositeBtnDiv {
    width: 100%;
}

.profileDepositeBtnDiv .ant-btn-primary {
    //   background-color: #0370ce !important;
    // margin-right: 0.5rem;
    max-width: 100%;
    width: 150px;
    font-weight: 500;
    letter-spacing: .5px;
    font-family: 'sansation';
    margin: auto;
    display: flex;
    justify-content: center;
}

.ant-tabs-tab {
    color: gray;
    opacity: 90%;
    letter-spacing: 0.4px;


}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: 'sansation';
    font-weight: 600;
    color: #0370ce !important;

}



.ProfileView .card {

    box-shadow: none;
    border: 0px;
}


.ProfileView .row {
    padding: 20px 10px;

    @media(max-width: 550px) {
        padding: 15px 6px 15px;

        h6 {
            font-weight: 400;
        }
    }
}

.ProfileView .row div:first-child {
    text-align: left !important;
}

.ProfileView .row div:nth-child(2) {
    text-align: right !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    height: 22px;
    overflow: hidden;
}

.userDetails .row div:nth-child(2) {
    text-align: right !important;
    text-overflow: unset;
    -webkit-line-clamp: unset;
    height: 100%;
    overflow: inherit;
}

.ProfileView .card-body {
    background-color: #000;
    padding: 0 10px !important;
}

.ProfileView .row:nth-child(even) {
    background-color: rgba(75, 75, 75, 0.1294117647);
}

.imgbxportfolio {
    .ant-avatar>img {
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}

.tabs_user {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c4c4c4;

    .tbs {
        border-bottom: 2px solid transparent;
        padding: 7px 5px;
        color: #fff;
        cursor: pointer;
        transition: .3s all ease;
        font-size: 15px;

        &.act {
            transition: .3s all ease;
            border-bottom: 2px solid #0370ce;
            color: #0370ce;
        }
    }
}

// .ProfileView .row:nth-child(odd){
//     background-color: #0370ce;
// }




// ====================== media profile new =============================


@media (max-width : 767px) {

    .profilrAvatar {
        display: flex !important;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
    }

    .profileNameDiv {
        &:not(.comp_prof) {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
    }

    .profileAmountDivWrapper {
        display: flex !important;
        padding: 0 !important;
    }

    .profileAmountDiv {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .profileDepositeBtnDiv {
        margin: 0 auto;
    }
}

@media (max-width : 340px) {

    .profileDepositeBtnDiv .ant-btn-primary {
        // margin-bottom: 1rem;
        width: 135px;
    }
}


// COMPANY PROFILE PDF

.pdf_wrapper {
    position: relative;
}

.pdf_wrapper .cntrlsld {
    position: absolute;
    top: 45%;
    width: 100%;
}

.pdf_wrapper .cntrlsld>div:nth-of-type(1) {
    position: absolute;
    left: 0;
}

.pdf_wrapper .cntrlsld>div:nth-of-type(2) {
    position: absolute;
    right: 0;
}

.pdf_container {
    min-height: 600px;
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; // background: lightGray;
    // width: 70%;
    margin: 2rem auto 0;
    overflow: auto;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;

    .react-pdf__Document {
        width: 100%;
        height: 100%;
    }
}

.smScreenAnchorList {
    background-color: lightgray;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    z-index: 100;
}

.smScreenAnchorOpenBtn {
    width: 100%;
    height: 50px !important;
    background-color: transparent !important;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    box-shadow: 1px 2px 2px 2px grey;
    border: none;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 15px;
}

.sideAnchorSection {
    position: sticky;
    top: 0px;
    z-index: 98;
}

.sideAnchorSection ul {
    grid-area: nav;
    // margin-top: 20rem;

}

.sideAnchorSection ul li a {
    color: white;
    font-family: 'sansation';
}

.sideAnchorSection ul li a:focus {
    color: rgb(23, 113, 215);
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 5px;
}


.sideAnchorSection ul li {
    font-weight: 500;
    line-height: 2rem;
    font-size: 15px;

    @media(max-width: 550px) {
        font-size: 14px;
    }
}


.headingsHR {
    width: 90%;
    margin-top: 1rem;
}

.sectionHeadingH4 {
    font-family: 'sansation';
    font-weight: 400;
}


#ulBulletsChange {
    list-style: none;
    list-style-position: outside;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

#ulBulletsChange li {
    color: gray;

}

//   #ulBulletsChange  li:before {
//     content: "\003E";
//     color:gray;
//     list-style-position: outside;
//     font-weight: bold;
//     font-size: 17px;
//     padding-right:5px;
//     }


.company_portfolio_name {
    margin: 1rem 0 1rem 0;
}

.company_portfolio_name h5 {
    font-size: 0.9rem;
    color: gray;
    margin: 0.3rem 0 0.5rem 0;
    font-family: 'sansation';
}

.tag {
    color: rgb(128, 126, 126);
    font-size: 12px;


}

.ProfileportFolio_secHeadings {
    font-weight: bold;
    font-family: 'sansation';
    font-size: 25px !important;
    text-align: center;
    color: white !important;
}

@media (max-width : 600px) {
    .ProfileportFolio_secHeadings {
        text-align: center;
    }
}

@media (max-width: 575px) {

    .ProfileView .row div:nth-child(2) {
        text-align: left !important;
        margin-top: 10px;
    }

}

.sectionSpacing {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.sectionSpacing:nth-last-of-type(1) {
    margin-bottom: 0rem !important;
}

.dataRoomTable tr,
td {
    text-align: center;
    padding: .5rem;
    border: none !important;
    background-color: transparent !important;
}

.logos {
    display: flex;
    justify-content: space-around;
}

.logos img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.dark-bg {
    background-color: black;
}

.profileUpdateBtn {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
    border: none !important;
    color: white !important;
}

.userProfileImgDiv {
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 1px 1px 3px 1px white;
    // width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-left: 10px;
    transition: 0.4s;
    overflow: hidden;
}

.userProfileImgDiv:hover {
    background-color: #555;
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.4s;

}

.userProfileImgDiv:hover svg {
    color: black;
}

.profileImg {
    height: inherit;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    position: relative;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .icons_box {
        position: absolute;
        height: 100%;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        opacity: 0;
        transition: .4s all ease;
    }

    &.act {
        .icons_box {
            transition: .4s all ease;
            opacity: 1;
        }
    }
}

.round-button {
    color: white;
    border: none;
    padding: 5px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 100px;
    height: 33px;
    width: 33px;
    background-color: #1f1f1f;

}

.round-button:active {
    background-color: #48abe0;
    box-shadow: 0 0 2px darkslategray;
    transform: translateY(2px);
}


.userProfileNavbar {
    background: rgba(8, 41, 139, 0.3);
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.2rem;
    padding: 9px;

    .ant-avatar>img {
        aspect-ratio: 1/1;
    }

    span {
        font-size: .5rem;
        font-weight: 600;

        &.usertitle {
            @media(max-width: 550px) {
                display: none;
            }
        }
    }
}

.ant-result-subtitle,
.ant-result-title {
    color: white !important
}

.userProfileDiv {
    width: 60%;
    height: inherit;
    display: flex;
    align-items: center;
}


@media(max-width:550px) {
    .userProfileNavbar {
        padding: 5px;
    }

    .sectionSpacing {
        margin: 0;
        padding: 0 1rem;
    }
}

mark {
    padding: 0.2em;
    background-color: #dae21f7d;
    border-radius: 5px;
}

.company_verify_bar {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    margin-bottom: 12px;
    backdrop-filter: blur(100px) brightness(0.6);
    padding: 7px;
    border-radius: 4px;
    margin-top: 2rem;

    .container {
        position: relative;
    }

    span {
        color: #48abe0;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    .close-ic {
        cursor: pointer;
        position: absolute;
        right: 7px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.verify_bar {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    margin-bottom: 12px;
    backdrop-filter: blur(100px) brightness(0.6);
    padding: 7px;
    border-radius: 4px;
    position: relative;
    top: 90px;

    .container {
        position: relative;
    }

    span {
        color: #48abe0;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    .close-ic {
        cursor: pointer;
        position: absolute;
        right: 7px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.otpmodal {
    .otpinputbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
        padding: 25px 0;

        >input {
            width: 36px !important;
            height: 36px !important;
            border: 1px solid #c4c4c4 !important;
            border-radius: 4px !important;
            margin-right: 0 !important;
        }
    }

    .otpresendbox {
        justify-content: center !important;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 10px;


    }
}

.update_form_level_two {
    input[type="radio"] {
        border: 1px solid #0370ce !important;
        width: 20px !important;
        height: 20px !important;
        margin-left: -30px !important;
    }

    label {
        color: #555 !important;
    }
}

.blbtn {
    background-color: #1737b3 !important;
    border: 1px solid #1737b3 !important;
}

.userProfileCardDiv {
    background-Color: #171717;
    width: 100%;
    height: 180px;
    border: 1px solid white;
    margin: 1rem auto;
    border-Radius: 1rem;
    display: inline-flex;
    align-Items: center;
    padding: 15px;
}

.userProfileDivOne {
    width: 400px;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.userProfileDivTwo {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px
}



@media(min-width:300px)and (max-width : 767px) {
    .userProfileCardDiv {
        width: 100%;
        height: inherit;
        border: 1px solid white;
        margin: 2rem auto;
        border-Radius: 1rem;
        display: flex;
        flex-direction: column;
        align-Items: center;
        gap: 14px;
    }


    .userProfileDivOne {
        width: 100%;
        height: inherit;
        margin-top: 10px;
        display: flex;
        align-items: flex-start;

        button {
            align-self: flex-start !important;
            height: fit-content !important;
            padding: 0 0 0 10px;
        }
    }

    .userProfileDivTwo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0;

        button {
            font-size: 16px;
        }

    }


    .profileImg {
        height: inherit;
        width: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .userProfileNameDiv {
        h3 {
            font-size: 17px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            margin-top: 5px;
        }
    }


}




@media(max-width : 500px) {
    .productBtn span {
        display: none;
    }

    .productBtn {
        width: 20% !important;
    }

}


.companyNo_VerifyBtn {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
    border: none !important;
    color: white !important;
    border-radius: 7px !important;
    box-shadow: 1px 1px 3px 1px navy;
}

.companyNo_VerifyBtn[disabled] {
    background-color: #ccc;
    /* Gray background color */
    color: #666;
    /* Dark gray text color */
    cursor: not-allowed;
    /* Change cursor to 'not-allowed' */
    opacity: 0.6;
    /* Reduce opacity to make it less prominent */
}

/* Basic styling for the verified tag */
.verified-tag {
    display: inline-block;
    padding: 4px 6px;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    font-size: 12px;
}

/* Optional: Add a checkmark icon */
.verified-tag::before {
    content: "\2713";
    /* Unicode checkmark character */
    margin-right: 4px;
}

.companyProfile .ant-tabs-content-holder {
    background: black;
    border: 1px solid white !important;
    border-radius: 10px;
}


input[disabled] {
    opacity: 80% !important;
    cursor: not-allowed;
}

.vrfydiv {
    display: flex;
    gap: 10px;

    p {
        color: #fff;
        background-color: #02004F;
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
        width: fit-content;
    }

}



@media (max-width : 550px) {

    .leadsHeading {
        font-size: 1.2rem !important;
    }
}



.upload-border {
    border: 1px solid black;
    width: 315px;
}

input[type=file]::-webkit-file-upload-button {
    width: 0;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    border: none;
    border: 0px;
}

x::-webkit-file-upload-button,
input[type=file]:after {
    content: 'Browse...';
    left: 100%;
    margin-left: 3px;
    position: relative;
    -webkit-appearance: button;
    padding: 3px 8px 2px;
    border: 0px;
}

.thumbnailOverActive {

    align-items: center;
    background-color: #0009;
    border-radius: 15px;
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    opacity: 0;
    position: absolute;
    transition: all .4s ease;
    width: 100%;
}
.header_wrapper{
    background-color: $primary-color;
    padding-top: 5rem;
}
.header_heading{
    padding-bottom:5px;
    font-size: 4rem;
}
.breadcrumb-item::before{
    color: #fff;
}
.abtmain{
    width: 100%;
    max-width: 1140px;
    padding: 15px;
    margin: auto;
    color: #fff;
    .c-fff{
        color: #fff;
    }
    h2{
        margin: 30px 0 20px;
    }
    .gallry_text{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 25px 40px;
        margin-top: 30px;
        @media (max-width: 992px){
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .gallry_img{
            width: 100%;
            max-width: 540px;
            height: 300px;
            border-radius: 25px;
        }
        .glry_txt{
            flex: 1;
            .glry_h2{
                font-size: 42px;
                @media (max-width: 992px){
                    font-size: 34px;   
                    font-weight: 600;
                }
            }
        }
    }
    .signup_btn{
        background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
        color: #fff;
        text-align: center;
        height: 50px;
        width: 175px;
        margin: 60px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border-radius: 15px;
    }
    .social_media_links{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
        margin-bottom: 40px;
        .social_btn{
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: #fff;
        }
    }
}

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

a,
a:hover {
    text-decoration: none;
}

a:hover {
    color: $white-color;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    overflow: overlay;
    overflow-x: hidden !important;
    font-family: $primary-font;
    font-size: 100%;
    font-weight: 400;
    background-color: #000;
}

/* ########################### Custom Scroll bar ################################ */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;

    @media (min-width: 1441px) {
        width: 10px;
        height: 10px;
    }
}

::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 20px
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, rgb(2, 0, 79), rgba(0, 96, 255, 1));
    border-radius: 20px
}

html,
body {
    scrollbar-color: linear-gradient(45deg, rgb(2, 0, 79), rgba(0, 96, 255, 1));
    scrollbar-width: thin;
    border: none
}

/* ################################### custom scroll bar ################################################## */
h1 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 1rem;
    color: $secondary-color
}

h2 {
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: capitalize;
    font-family: $secondary-font;
    color: $secondary-color;
    line-height: 3rem;
}

h4 {
    color: $secondary-color;
    font-family: $secondary-font;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

h5 {
    color: $primary-color;
    text-transform: capitalize;
    font-family: $secondary-font;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

h6 {
    font-size: 1rem;
    font-weight: 700;
}

p {
    font-size: 1rem;
    color: $text-color;
    font-weight: 400;
    line-height: 1.5rem;
    // letter-spacing: 1px;
}

.nav-item .nav-link {
    cursor: pointer;

    &:hover {
        color: $primary-color;
    }
}

.nav-item {

    .active {
        color: $primary-color;
    }
}
.expired-notice {
    text-align: center;
    padding: 2rem;
    border-radius: 0.25rem;
    margin: 0.5rem;
}

.expired-notice>span {
    font-size: 2rem;
    font-weight: bold;
    color: red;
}

.expired-notice>p {
    font-size: 1.3rem;
}

.show-counter {
    padding: 0.5rem;
}

.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
    gap: 10px;
}

.show-counter .countdown {
    line-height: 1.25rem;
    padding: 10px;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
        min-width: 60px;
    height: 60px;
    justify-content: center;

    @media (max-width:576px) {
        min-width: 40px;
        height: 50px;
    }

}

.show-counter .countdown.danger {
    color: #ff0000;
}

.show-counter .countdown>p {
    margin: 0;
    font-size: 24px;
    color: #fff;
    font-weight: 700;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
    content: none !important;
}

.datebx .rdrCalendarWrapper{
    max-width: none !important ;
}

.rdrMonthsVertical{
    flex-direction: inherit !important;
}

.rdrMonth{
    width: 100% !important ;
}

.rdrDayToday .rdrDayNumber span:after {
    content: none !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
    border: none !important;
}

.rdrDay.rdrDayToday {
    background-color: rgb(61, 145, 255);
    border-radius: 25px;
}

@media(max-width: 550px) {
    .show-counter .countdown-link {
        flex-wrap: wrap;
    }

    .show-counter .countdown>p {
        font-size: 18px;
    }
}
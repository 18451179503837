.banner-heading-primary {
    color: $primary-color;
}

.bg-img {
    // background-image: url('../../images/bgimgLogin.jpg');
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url(../../images/bgimgLogin.jpg);
    ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 22%;
    width: 100%;
    // height: 100vh;
}

@media (min-width : 2200px) {
    .bg-img {
        min-height: 100vh;
    }
}

.heroWrapper {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url('../../images/bgimgLogin.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 22%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 550px;
    // max-height: 660px;
    position: relative;
    z-index: 1;

    @media(min-width: 1800px) {
        background-size: cover;
        max-height: 100vh;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 1));
        z-index: -1;
    }

    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 79.07%, #000000 98.89%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../images/heroBanner.jpg'), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%), url('../../images/heroBanner.jpg');
    .hero_inner {
        text-align: center;
        max-width: 1260px;
        width: 100%;
        padding: 20px;
    }
}

.commonH2 {
    font-weight: 700;
    font-family: 'sansation';
    text-align: center;
    color: white;
    line-height: 50px;
}

.banner-sec-text {
    font-family: '' sansation'';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 26px;
    text-align: center;
    color: white;

}

.banner-btn {
    background: $homeBtnGradient;
    border-radius: 10px;
    padding: 10px 20px;
    color: white;
    position: relative;
    right: 0;
    outline: none;
    border: 1px solid transparent;
    width: 100%;
    max-width: 160px;
}

.rightArrow {
    content: "";
    // background-image: url('arrow.png');

    font-size: 17px;
    transition: transform 0.3s ease;
}

.banner-btn:hover .rightArrow {
    transform: translateX(10px);
}

.allStartupbutton:hover .rightArrow {
    transform: translateX(10px);
}

.allStartupbutton {
    background: $homeBtnGradient !important;
}



.email_section {
    display: flex !important;
    justify-content: center;
    // align-items: center;
    max-width: 460px;
    width: 100%;
    margin: auto;

}

.email_section button {}

.email_section input {
    width: 50%;
}

.heroWrapper h2 span {
    color: $HomePrimaryColor;
}

.heroBtn {
    background-color: $HomePrimaryColor !important;
    border: 1px solid $HomePrimaryColor !important;
}

.heroWrapper .ant-col div {
    padding-left: 2rem;
}


.allStartupbutton {
    background-color: $HomePrimaryColor !important;
    border: 1px solid $HomePrimaryColor !important;
    font-weight: 500 !important;
    padding: 1rem 1.5rem !important;
    box-shadow: 1px 1px 6px 2px '#294ccc5';


}

.allStartupbutton:active {
    background-color: navy !important;
}

.allStartupbutton:hover {
    background-color: white !important;
    color: $HomePrimaryColor !important;
}

.productSelectDiv .ant-select-selector {
    height: 50px !important;
    border-radius: 18px !important;
    overflow-y: scroll;
}

.productSelectDiv .ant-select-selector input {
    margin-top: 8px !important;
}

.productSelectDiv .ant-select-selector .ant-select-selection-item,
.productSelectDiv .ant-select-selector .ant-select-selection-placeholder {
    padding-top: 8px !important;
}

.productSelectDiv .ant-select-clear {
    right: 2rem !important
}


@media (max-width : 768px) {
    .heroWrapper .row:first-child {
        text-align: center;
        border: 2pc solid;
    }

    .commonH2 {
        font-size: 1.8rem;
        line-height: 40px;

    }

    .banner-sec-text {
        font-size: 14px;
        line-height: 20px;
    }
}


@media (max-width : 450px) {


    .commonH2 {
        font-size: 1.5rem;
        line-height: 30px;

    }

    .banner-sec-text {
        font-size: 12px;
        line-height: 18px;
    }

    .banner-btn {
        padding: 8px 10px;
        right: 0;
        font-size: 13px;
        max-width: 130px;
    }

    .productSelectDiv .ant-select-selector {
        height: 40px !important;
    }

    .productSelectDiv .ant-select-selector .ant-select-selection-item,
    .productSelectDiv .ant-select-selector .ant-select-selection-placeholder {
        padding-top: 4px !important;
    }

    .rightArrow {
        font-size: 13px;
    }

    .banner-btn:hover .rightArrow {
        transform: translateX(6px);
    }
}




@media (min-width : 1600px) {
    .heroWrapper h2 {
        font-weight: 700;
        font-family: 'sansation';
        font-size: 3.2rem;
        line-height: 1.2em;
    }

    .heroWrapper .ant-col div {
        padding-left: 3rem !important;

    }
}

.slick-prev,
.slick-next {
    bottom: 0;
}

.investorSliderImg {
    object-fit: cover;
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    filter: blur(1px);
}

.investorSlider {
    border-radius: 10px;
    width: 300px;
    border: 1px solid gray;
}

.invest_slider_relative {
    position: relative;
    aspect-ratio: 1/1;

    > {
        .investorSliderImg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            aspect-ratio: 1/1;
            object-fit: cover;
            filter: blur(0px) !important;
        }

        div {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            display: flex;
            align-items: flex-end;
            background: linear-gradient(to bottom, transparent 75%, #0e0c48) !important;
            border-radius: 10px;

            p {
                font-size: 20px;
                font-weight: 700;
                color: white;
            }
        }
    }
}

.productSliderCard .categoryCard {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    bottom: -2px;
    color: white;
    font-weight: bold;
    font-family: 'sansation';
    left: -1px;
    padding: 10px;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(248.87deg, rgba(5, 88, 226, 0.8) 13.57%, rgba(40, 139, 255, 0) 84.66%), linear-gradient(0deg, #040D0E, #040D0E), #D9D9D9;
    width: 100%;
    top: auto;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    padding-bottom: 1rem !important;
}

.productSliderGrayScale {
    filter: grayscale(1);
    transition: .3s all ease;
}

.productSliderGrayScale:hover {
    filter: grayscale(0);
    transition: .3s all ease;
}


.slick-dots li button:before {
    color: white !important;
    font-size: 12px !important;
    font-weight: bold;
}

.slick-dots li.slick-active button::before {
    color: $homeBtnGradient !important;
}

.prdsldwrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.sldbx .slick-slide>div {
    padding: 0 10px;
    display: flex;
    justify-content: center;
}

.investorSliderWrapper {
    display: flex !important;
    justify-content: center;
    height: auto;
}

.slick-prev:before,
.slick-next:before {
    color: white !important;
}


.slick-dots li button:before {
    color: #000;
}

.slick-dots li.slick-active button:before {
    color: #000;
}

.slider-card {
    margin: 0 10px;
}

#investor_slider_sec .slick-dots {
    bottom: -3rem !important;
}

.slick-prev,
.slick-next {
    bottom: 20px;
    z-index: 1;
}

.slick-prev {
    left: -14px !important;
}

.slick-next {
    right: -14px !important;
}

.exploreCardWrapper {
    height: 100%;
    width: 350px;
    max-width: 100%;

    .icon-bx {
        display: flex;
        gap: 5px;
        position: absolute;
        top: 30px;
        right: 30px;
    }
}

@media (max-width : 420px) {
    .exploreCardWrapper {
        width: auto;
    }
}

.exploreCardWrapper .ant-card-body {
    padding: 0 !important;
}


.exploreCardWrapper .ant-card {
    height: 100%;
    padding: 20px 20px 6px;
    border-radius: 20px;
    color: white !important;
}

.exploreCardWrapper .ant-card .ant-card-cover img {
    border-radius: 20px;
    aspect-ratio: 3/2;
    width: 100%;
}

.sectionSpacing {
    margin-top: 3rem;
    margin-bottom: 3rem;

}

.exploreCardWrapper .ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
    color: white;
    padding-top: .5rem;
}

// .exploreCardWrapper .ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-description,
// .exploreBottomText {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 14px;
// }
.expflx {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.expflx .exploreBottomText {
    flex: 50%;
}

.exploreCardWrapper .ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-description,
.exploreBottomText p {
    color: white;
    font-size: 14px !important;
}

.exploreCardWrapper .blrcrd {
    filter: blur(4.5px);
    background-color: #171717;
    cursor: pointer;
}

.crdexpbg {
    background: linear-gradient(248.87deg, #171717 13.57%, #171717 84.66%), linear-gradient(0deg, #171717, #171717), #171717 !important;
    border-radius: 21.4308px !important;
    cursor: pointer;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #000 #000 #fff #fff !important;
    transition: .3s all ease !important;
    position: relative !important;
    z-index: 1;
}

.crdexpbg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 21.4308px !important;
    background: linear-gradient(248.87deg, #171717 13.57%, #171717 84.66%), linear-gradient(0deg, #171717, #171717), #171717 !important;
    opacity: 0;
    transition: .3s all ease;
    z-index: -1;
}

.crdexpbg:hover::before {
    background: linear-gradient(248.87deg, rgba(5, 88, 226, 0.8) 13.57%, rgba(40, 139, 255, 0) 84.66%), linear-gradient(0deg, #040D0E, #040D0E), #D9D9D9 !important;
    opacity: 1;
    transition: .3s all ease;
}

.crdexpbg:hover {
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #000 #000 #fff #fff !important;
    transition: .3s all ease !important;
}

.crdexph3 {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    margin: 10px 0 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px;
}

.crdlogo {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #fff;
    margin-top: -30px;
    aspect-ratio: 1/1;
}

.crdexppra {
    font-size: 14px !important;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #E6E8EC;
    margin-bottom: 0px;
    padding-bottom: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 55px;
}

.leadgenBtn {
    position: relative;
    right: 0 !important;
}

// .desc-emty-card{
//     height: 50px;
// }

.gradient {
    background: linear-gradient(248.87deg, rgba(5, 88, 226, 0.8) 13.57%, rgba(40, 139, 255, 0) 84.66%), linear-gradient(0deg, #040D0E, #040D0E), #D9D9D9 !important;
    border-radius: 21.4308px !important;
    cursor: pointer;
}

.HowWorksContent img {
    width: 76px;
}

.HowWorksContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 1.5rem;
}

.HowWorksContent h3,
.RaiseRound h3 {
    color: white;
    font-family: 'sansation';
    font-weight: 600;
}


.RaiseRound {
    background: linear-gradient(0deg, #171717, #171717), #D9D9D9;
    border: 1px solid white;
    border-radius: 34px;
    padding: 30px;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
}


.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: white !important;
}

.ant-collapse-content-box {
    border-top: 2px solid black !important;
}


.gradientBtn {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
    border-radius: 13.8462px;
    padding: 8px 40px !important;
    text-align: center;
    border: 1px solid navy !important;
}

.gradientBtn.graylog {
    filter: grayscale(1);
    cursor: not-allowed;
}

@media (max-width : 768px) {
    .gradientBtn {
        padding: 6px 30px !important;
    }
}


.rightArrow {
    content: "";
    // background-image: url('arrow.png');
    // margin-left: 5px;
    font-size: 17px;
    transition: transform 0.3s ease;
}


.gradientBtn:hover {
    background: linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
    border-radius: 13.8462px;
    color: white !important;
    border: 1px solid navy !important;
}




.gradientBtn.graylog:hover {
    border-radius: 5px;
}

.gradientBtn:hover .rightArrow {
    transform: translateX(10px);
}

.gradientBtn.graylog:hover .rightArrow {
    transform: translateX(0px);
}

.border-gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
}

.createAccBtn-loginPage {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
    padding: 10px 18px;

    border-radius: 18px;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'sansation';
    min-width: 250px;

    border-radius: 18px;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'sansation';
    min-width: 250px;
    border: 1px solid navy;
    border-radius: 10px;
    transition: 0.4s;
    background-origin: border-box;
    background-clip: padding-box;
}



@media (min-width : 768px) {
    .createAccBtn-loginPage {
        width: 300px;
    }
}


.createAccBtn-loginPage:hover {
    border-radius: 20px;
    transition: 0.4s;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.4) -77.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.4) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;



}


@media (max-width : 550px) {
    .sectionSpacing {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

    }

    // .exploreCardWrapper .ant-card .ant-card-cover img {
    //     height: 200px;
    // }

    .exploreCardWrapper .ant-card {
        padding: 10px;

    }
}


.whyToSignUpCard .start .whyToSignUpCard-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;

}

.whyToSignUpCard .end .whyToSignUpCard-wrapper {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    align-items: center;

}

.whyToSignUpCard .end p {
    text-align: right;
}

.whyToSignUpCard .start {
    background: linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);
    border-radius: 25px;
    border-left: 1px solid navy;
    transition: 0.4s;

}


.whyToSignUpCard .start:hover {
    border: 1px solid navy;
    transition: 0.4s;
}

#howMuchDoesItCost {
    margin-top: 3rem;
    min-height: 300px;
}

.bg {
    // background-image: url('../../images/bgimgLogin.jpg');
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url(../../images/bgimgLogin.jpg);
    ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%, 100%;
    width: 100%;
    min-height: 450px;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    position: relative;
    // overflow: hidden;
}

@media (min-width : 992px) {

    .blackBg {
        width: 100%;
        background-color: black;
        height: 86px;
        position: absolute;
        top: -45px;
        transform: rotate(184deg);
        right: -4px;
    }
}

@media (min-width : 768px) {
    .productSlider {
        display: flex;
        justify-content: center;
        gap: 2rem;
        align-items: center;
    }
}

@media (max-width : 768px) {

    .productSlider {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
    }
}

.productSliderImg {
    object-fit: cover;
    width: 300px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    height: 300px;
    border-radius: 10px;
    filter: opacity(0.8) blur(1px);
    position: absolute;
    top: 0;
    left: 0;
}

.productSliderCard {
    border: .2px solid #80808040;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 3px 8px 0px rgba(182, 180, 180, 0.6);
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
    transition: 0.3s all ease;
    height: 300px;
    width: 300px;
    cursor: pointer;
}

.productSliderCard:hover {
    transition: .3s all ease;
}

.productSliderCard.blrcrd {
    filter: blur(4.5px);
}

.hvreff::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 300px;
    height: 0px;
    border-radius: 15px;
    background: linear-gradient(to top, rgba(0, 96, 255, 0.8) 0%, transparent 60%);
    opacity: 0;
    z-index: 1;
    transition: .3s all ease;
}

.hvreff:hover::before {
    opacity: 1;
    transition: .3s all ease;
    height: 300px;
}

.productSliderCard div {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: absolute;
    top: 0px;
    color: white;
    font-weight: bold;
    font-family: 'sansation';
    left: 0;
    padding: 10px;
    background: rgb(0 0 0 / 33%);
    width: 100%;
}


.productSliderCard div h5 {

    color: white;
    font-weight: 600;
    letter-spacing: .5px;
    font-family: 'sansation';
}

.productSliderCard div p {
    font-size: 12px;
    line-height: 16px;
    color: white;
}


.whyToSignUpCard .end {
    background: linear-gradient(94.76deg, rgba(5, 88, 226, 0) 37.74%, rgba(5, 88, 226, .2) 99.38%);
    border-radius: 25px;
    border-right: 1px solid navy;

}

.whyToSignUpCard .end:hover {

    border: 1px solid navy;

}

.whyToSignUpCard h1 {
    color: rgba(58, 132, 255, 1);
    font-weight: bold;
}

.whySign_wrapper {
    position: relative;
}

@media screen and (min-width : 992px) {

    .whySign_wrapper .RightArrow {
        display: block;
        position: absolute;
        left: 50%;
        top: 25%;
        width: 18% !important;
    }


    .whySign_wrapper .LeftArrow {
        display: block;
        position: absolute;
        left: 30%;
        top: 26%;
        width: 20% !important;
    }
}

@media screen and (min-width : 768px) and (max-width : 992px) {

    .whySign_wrapper .RightArrow {
        display: block;
        position: absolute;
        left: 50%;
        top: 30%;
        width: 25% !important;
    }


    .whySign_wrapper .LeftArrow {
        display: block;
        position: absolute;
        left: 22%;
        top: 35%;
        width: 28% !important;

    }
}


@media screen and (max-width : 768px) {

    .whySign_wrapper .LeftArrow,
    .whySign_wrapper .RightArrow {
        display: none;
    }
}

.dashboard_child_pages {
    min-height: 100%;

}

// .dashboard_child_pages::-webkit-scrollbar {
//     display: none;
//   }

/* Hide scrollbar for IE, Edge, and Firefox */
.dashboard_child_pages {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.leadgenVideoCard {
    border: 1px solid gray;
    border-radius: 12px;
    box-shadow: 1px 1px 3px 1px rgba(211, 211, 211, 0.43);

}

.leadgenVideoCard[poster] {
    height: 80%;
    width: 100%;
    object-fit: contain;

    .video-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        /* 16:9 aspect ratio, adjust as needed */
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


}

@media(max-width: 550px) {
    .whyToSignUpCard-wrapper {
        h1 {
            font-size: 36px !important;
            font-weight: 600 !important;
        }

        img {
            width: 12% !important;
        }
    }
}


.saveBtn {
    font-size: 0.7rem !important;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
    padding: 5px 10px !important;
    align-self: center;
    margin-left: 10px;
}

.saveBtn:hover {
    background: linear-gradient(261.66deg, rgba(10, 51, 119, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);

}

.saveBtn:focus {
    background: linear-gradient(261.66deg, rgba(10, 51, 119, 0.1) -47.14%, rgba(60, 139, 255, 0) 179.19%), linear-gradient(0deg, #0e0c58, #02004F), rgba(217, 217, 217, 0.416);

}

@media(max-width: 425px) {
    .abth2 {
        padding-top: 100px;
    }
}

.home-faqs {
    .faqs-asked {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 26px;

        .faq-accordion {
            transition: .8s all ease;

            h4 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                font-size: 20px;
                cursor: pointer;
                padding: 14px 20px;
                background-color: #1a1a1a;
                margin-bottom: 1px;
                border-radius: 14px;

                span {
                    transform: rotate(45deg);
                    transition: .3s all ease;
                }
            }

            .faq-content {
                padding: 0px 20px;
                background-color: #4e4e4e;
                transition: .8s all ease;
                height: 100%;
                max-height: 0px;
                visibility: hidden;
                overflow: hidden;
                border-radius: 0 0 14px 14px;

                p {
                    color: #fff;
                    margin-bottom: 0;
                }
            }

            &.act {
                h4 {
                    background-color: #4e4e4e;
                    transition: .3s all ease;
                    border-radius: 14px 14px 0 0;

                    span {
                        transform: rotate(0deg);
                        transition: .3s all ease;
                    }
                }

                .faq-content {
                    transition: 1s all ease;
                    max-height: 300px;
                    visibility: visible;
                    padding: 14px 20px;
                }
            }

            &:hover {
                transition: .3s all ease;

                h4 {
                    background-color: #4e4e4e;
                }
            }
        }
    }
}

.no_comp_data {
    width: 100%;
    position: relative;

    .blur_box {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 14px;
        // background-color: rgb(0 24 133 / 60%);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            color: #fff;
            font-weight: 600;
            background: rgb(0 24 133 / 40%);
            padding: 10px;
            border-radius: 8px;
            font-size: 2.5rem;
        }
    }

    .row {
        >div {
            filter: blur(4px);

            .exploreCardWrapper {
                width: 100%;
            }
        }
    }
}
$primary-color: #007CE9;
$secondary-color: #000000;
$third-color: #f98169;
$white-color: #ffffff;
$text-color: #555;
$text-grey: rgb(245, 250, 250);
$black-color: #000;
$primary-font: 'sansation', "sans-serif";
$secondary-font: 'sansation', "sans-serif";
$offcanvas-width: 270px;
$HomePrimaryColor: #294ccc;
// $homeBtnGradient:linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000,
$homeBtnGradient: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
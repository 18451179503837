.switchOff {
  width: 90%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  color: #fff;
  padding: 10px;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.switchOff h5 {
  margin-left: 10px;
  margin-top: 5px;
}


.switchOn {
  border: 1px solid lightGray;
  width: 90%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  background: lightgreen
}

// Review Section 

.piechart {
  max-height: 400px !important;
}

// End of Review Section


.instaicon {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}

.bg-img-portfolio {
  // background-image: url('../../images/bgimgLogin.jpg');
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url(../../images/dashboardBgImg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%, 100%;
  width: 100%;
  // overflow: hidden;
  padding-top: 95px;
  // height: 100vh;
}

.TopanchorList {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
  color: white;
  white-space: nowrap;
  width: 90%;
  margin: 0 auto;
  overflow-x: scroll;

  @media(max-width: 550px) {
    padding: 16px;
  }
}

.topAnchorListWrapper {
  background: linear-gradient(180deg, #003C9F 50%, rgba(0, 60, 159, 0) 100%);
  backdrop-filter: blur(2.00278px) !important;
  // overflow-x: scroll;
  position: relative;
  width: 100%;
  grid-area: nav;

  .viewPortfolioBtn {
    position: absolute;
    bottom: -50px;
    right: 16px;
  }
}

.topAnchorListWrapperScroll {
  background: linear-gradient(180deg, #003C9F 50%, rgba(0, 60, 159, 0)100%);
  backdrop-filter: blur(2.00278px) !important;
  // overflow-x: scroll;
  width: 100%;
  grid-area: nav;
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
  z-index: 50;
}


.portfolioVideoSlider {
  display: flex !important;
  justify-content: center;
}

.topAnchorListWrapper::-webkit-scrollbar {
  width: 5px !important;
  /* width of the entire scrollbar */
}

.topAnchorListWrapper::-webkit-scrollbar-track {
  background-color: none;
  /* color of the tracking area */
}

body .topAnchorListWrapper::-webkit-scrollbar-thumb {
  background-color: #003c9f4f;
  /* color of the scroll thumb */
  width: 5px;
  -webkit-width: 5;
  scrollbar-width: 5px
}

.fundraiseDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 300px;
  width: 210px;

  img {
    width: 40px;
    height: 40px;
  }

  li {
    span {
      font-weight: 600;
      font-size: 17px;
    }
  }

}

.company_high {
  background: linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);
  border-radius: 25.6258px;
  margin-top: 1.5rem;
  padding: 1rem;
  width: 100%;
}


.company_high:nth-child(even) .companyHighCard .highlightContentWrapper {
  display: flex;
  align-items: end !important;
  text-align: right;

}

.highlightContentWrapper {
  box-shadow: none !important;
  border: none;
}


.highlightDigit {
  color: #3A84FF;
  font-weight: bold;
}


.ourTeamCardBody .ant-card-body {
  padding: 10px !important;
  background: linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ourTeamCardBody {
  border-radius: 10px;
  box-shadow: 1px 1px 3px 2px #00000054;
  border: 1px solid;
  border-color: black;
}

.ourTeamCardBody .ant-card {
  border-radius: 10px !important;
  border: none;
  background: linear-gradient(0deg, #171717, #171717), #FFFFFF;
  height: 100%;

}

.ourTeamCardBody:hover .ant-card {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;


}

.ourTeamCardBody:hover .ant-card-body,
.ant-card {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;


}

.teamCardDesc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ourTeamCardBody .ant-card-cover {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

}

// .h4.ant-typography {
//   !important;
// }

.investorPerksDiv {
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(320.65deg, rgba(0, 96, 255, 0.8) -67.7%, rgba(40, 139, 255, 0) 79.29%);
  border-radius: 25px;
  color: white;
  padding: 1.5rem
}

.investorPerksDiv .ant-row div p {
  color: white !important;
}


.connectCompanyBtn {

  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
  border-radius: 12px !important;
  color: white !important;
}

.videoArrowCenter {

  font-size: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  margin-right: 5px;

}

.playIcon {
  height: 70px;
  width: 70px;
  border-radius: 100px;
  background-color: #00000070;
  padding: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 70;

}

.videoSliderImg {
  object-fit: cover;
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  max-width: 100%;
  aspect-ratio: 3/2;
  border-radius: 10px;
  filter: opacity(0.8) blur(0.75px);
  position: relative;

}

#investor_slider_sec {
  .slick-slider {
    .slick-slide {
      >div {
        padding: 0 10px;
      }
    }
  }
}

.gallery_sec {
  max-width: 100%;

  .slick-slider {
    width: 100%;

    .slick-slide {
      >div {
        padding: 0 10px;
      }
    }
  }
}

.playIcon:hover {
  background-color: black;
}

.marketLandscapeCard {
  background: linear-gradient(210.71deg, rgba(244, 248, 255, 0.8) -237.18%, rgba(40, 139, 255, 0) 59.74%), #171717 !important;
  border-radius: 20px !important;
  padding: 0 2rem 0 2rem !important;
}

.textEditorInnerData p {
  color: white !important;
}

.marketLandScapeViewPortfolio {
  position: relative;
  padding: 30px;
  margin-top: 40px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    background: linear-gradient(380deg, #033BB0 5%, rgba(40, 139, 255, 0) 95%);
    border-radius: 20px;
    z-index: -1;
  }

  h3 {
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 700;

    >span {
      position: absolute;
      top: -55px;
      left: -35px;
      z-index: -1;
      opacity: .45;
    }
  }
}

.fillingInViewPortfolio {
  border-radius: 10px;
  background-color: #171717;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 15px;
  display: flex;
  gap: 16px;
  max-width: 320px;

  >img {
    width: 70px;
    object-fit: scale-down;
  }

  a {
    display: block;
    width: fit-content;
    max-width: calc(100% - 90px);
    color: #00adee;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-transform: uppercase;
  }

  .filing_cont {
    width: calc(100% - 90px);
    color: #fff;

    >* {
      color: #fff;
    }

    p {
      margin-bottom: 5px;
    }

    a {
      display: block;
      width: fit-content;
      max-width: calc(100% - 120px);
      color: #00adee;
      text-decoration: underline;
      text-underline-offset: 5px;
      text-transform: uppercase;
    }
  }
}

.dataRoomViewPortfolio .ant-collapse-header {
  background: rgba(78, 78, 78, 1);
  color: white;
  border-radius: 15px !important;

}

.dataRoomViewPortfolio .ant-collapse-content .ant-collapse-content-box {
  background: #17181B !important;
  color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

}

.dataRoomViewPortfolio .ant-collapse-content {
  border-radius: 15px !important;
  border: none !important;
}

.dataRoomViewPortfolio .ant-collapse {
  background-color: transparent !important;
  border: none !important;
  // border-radius: 15px !important;

}

.ant-collapse-item {
  border: none !important;
}

.dataRoomTable th {
  color: white !important;
  font-weight: 400 !important;
  background-color: transparent !important;
}

// .dataRoomTable tbody tr td{
//   color: white !important;
//   background-color: gray !important;
//   border-radius: 15px;
//   padding: .3rem;
//   margin-top: 10px;
// }

.dataRoomTable tbody tr td a h4,
.dataRoomTable tbody tr td span {
  color: white !important;
  background-color: gray !important;
  border-radius: 15px;
  padding: .5em;
  margin-top: 10px;
  font-weight: 600;
}

.dataRoomTable tbody tr td a h4 {
  margin-bottom: 5px !important;
  margin: -5px 0 0 0 !important;
  padding: 5px !important;
  border-radius: 20px !important;
  font-size: 17px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: none !important;
}

.reviews .slick-slide:hover .review-Wrapper {
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 20px;
  text-align: center;
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-radius: 30px;
  min-height: 200px;
  color: white;
  border: 1px solid white;
  margin-left: 2em;

}


.reviews .slick-slide .review-Wrapper {
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 20px;
  color: white;
  text-align: center;
  background: linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-radius: 30px;
  margin-left: 2em;
  height: 250px;
}

.progressRoundBar .ant-progress-inner {
  height: 80px !important;
  width: 80px !important;

}


.roundBarWrapper {
  .ant-progress-circle .ant-progress-inner {
    background-color: #0b243a !important;
  }

  strong {
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.gradientBorder {

  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

}

.proceedInvestTitle {
  letter-spacing: 1.5px;
  line-height: 10px;
  font-size: 17px;
}

.ourTeamCardBody .ant-card .ant-card-bordered {
  background: black !important;
  object-fit: contain;
  border-radius: 10px;
}

.ourTeamCardBody .ant-card-cover img {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
}


// MANAGE PORTFOLIO NEW STYLING
.vertical-tabs-container .ant-tabs-tab {

  display: block;

  width:
    100%;

  margin-bottom:
    10px;
}

.vertical-tabs-container .ant-tabs-tab-btn {

  width:
    100%;

  text-align: left;
}

.vertical-tabs-container .ant-tabs-tab-content {

  margin-left:
    24px;
}



.portfolioTabsSection {

  background: rgba(255, 255, 255, 0.1);
  /* blur */

  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
}


.publishBtn {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
  border-radius: 8px !important;

  &.publishbtnportfolio {
    display: flex;
    align-items: center;
    margin: auto;
    font-size: 18px;
    height: 45px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      svg {
        animation: mymove 2s infinite;
        position: relative;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -65%;
      height: 45px;
      border-radius: 8px !important;
      background: linear-gradient(to right, #0a07a7e1 50%, transparent 60%);
      width: 100%;
      transition: .3s all ease;
      z-index: -1;
    }

    &:hover {
      &::before {
        left: 0;
      }
    }

    @media(max-width: 550px) {
      font-size: 16px;
    }
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
    right: 0px;
  }

  20% {
    opacity: 1;
  }

  100% {
    right: -40px;
  }
}

.viewPortfolioBtn {
  background: rgba(217, 217, 217, 0.5) !important;
  border-radius: 12.9286px !important;
  color: white !important;
}


.ant-tabs {
  padding-right: 1rem !important;

  /* Custom styles for the entire Tabs component */

}


// .vertical-tabs-container .ant-tabs-tab {
//   border: 1px solid;
//   border-color: gray gray black black;
//   background: rgba(23, 23, 23, 0.6) !important;
//   // padding: 1rem !important;
//   border: 1px solid gray gray black black !important;
//   border-radius: 10px !important;
//   color: white !important;

// }


.ant-tabs .ant-tabs-nav {
  border: none !important;
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab-active {

  color: white !important;
  font-weight: bold;


}

.vertical-tabs-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  font-weight: 500;
}



.vertical-tabs-container .ant-tabs-ink-bar {
  background-color: transparent !important;
  // border: .5px solid #1A2B3A !important;
  border-radius: 10px !important;
}

.vertical-tabs-container .ant-tabs-nav {
  padding-right: .7rem;
}



.vertical-tabs-container .ant-tabs-tab {
  align-items: center;
  appearance: none;
  background: rgba(23, 23, 23, 0.6) !important;
  border: 1px solid;
  border-color: gray gray black black;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  padding: 10px 24px 10px 24px !important;

}

.vertical-tabs-container .ant-tabs-tab:focus {
  box-shadow: #033BB0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #033BB0 0 -3px 0 inset;
}

.vertical-tabs-container .ant-tabs-tab:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #033BB0 0 -3px 0 inset;
  transform: translateY(-2px);
}


.vertical-tabs-container .ant-tabs-tab-active {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(23, 23, 23, 0.4) !important;
  border-radius: 10px !important;
  color: white !important;
  box-shadow: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #033BB0 0 3px 7px inset;
  transform: translateY(2px);
}

.ant-tabs-content-holder {
  border: none !important;
  /* Border for the content part */
  border-top: transparent;
  /* Remove the top border so that there is no overlap*/
}

.companyHighCard {
  img {
    width: 50px !important;
  }

  h1 {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
}

@media(max-width: 991px) {
  .proceedInvestTitle {
    width: 100%;
  }

  .portfolioTabsSection {
    >div {
      >div {
        &.col {
          h2 {
            font-size: 26px;
          }
        }
      }
    }
  }
}

// @media (max-width: 800px) {
//   .vertical-tabs-container .ant-tabs-tab {
//     width: fit-content;
//     margin-bottom: 0;
//   }

//   .vertical-tabs-container .ant-tabs-top>.ant-tabs-nav:before {
//     border-bottom: none !important;
//   }
// }

@media screen and (max-width : 600px) {
  .vertical-tabs-container .ant-tabs-tab {
    padding: 10px 5px 10px 5px !important
  }
}

.review-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to clamp */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-selection-placeholder {
  color: gray !important;
}


.videoSlider {
  border: 0.2px solid rgba(128, 128, 128, 0.2509803922);
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 3px 8px 0px rgba(182, 180, 180, 0.6);
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
  transition: 0.3s all ease;
  height: auto !important;
  width: 300px;
  cursor: pointer;
}

.videoSlider div {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  top: 0px;
  color: white;
  font-weight: bold;
  left: 0;
  padding: 10px;
  background: rgb(0 0 0 / 33%);
  width: 100%;
}


.videoSlider div h5 {
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.primaryVideoUserSide {
  border: 1px solid gray;
  border-radius: 18px;
  box-shadow: 1px 1px 7px 1px #efeff63d;
  width: "-webkit-fill-available";
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3/2;

}

.primaryVideoUserSide.vidbox {
  >div {
    height: 100% !important;

    video {
      aspect-ratio: 3/2;
      object-fit: cover;
      border-radius: 18px;
    }
  }
}

// @media (min-width:1000px) {
//   .primaryVideoUserSide {
//     height: 350px;
//   }
// }

.primaryVideoImage {
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 10px;
  filter: opacity(0.6) blur(1.5px);
  position: relative;
  aspect-ratio: 3/2;
}


.next-Pre-btn {
  width: 80% !important;
  /* right: 0; */
  /* float: right; */
  display: flex;

  justify-content: space-between;

  flex-wrap: wrap;
  margin-left: 20%;
}

.previousBtn,
.nextBtn {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
  color: #fff;
  border: 1px solid navy;
  outline: none;
  min-width: 100px;
  width: -moz-fit-content;
  width: 150px;
  height: 40px;
  padding: 4px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.4s;
  box-shadow: 1px 1px 3px px navy;
}

.previousBtn:hover,
.nextBtn:hover {
  transition: 0.4s;
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.9) -47.14%, rgba(70, 139, 255, 0) 100.19%), linear-gradient(0deg, #02004F, #02004F), rgba(287, 217, 217, 0.5);

}

.previousBtn[disabled],
.nextBtn[disabled] {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.1) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.1);
  opacity: 0.5;
}




.slider-container {
  width: 300px;
  /* Adjust the width as per your requirements */
  overflow: hidden;
  position: relative;
}

.slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slider a {
  flex: 0 0 auto;
  padding: 8px;
  background-color: #ccc;
  margin-right: 10px;
  color: #000;
  text-decoration: none;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  z-index: 10;
  background: none !important;
  box-shadow: none !important;
  font-size: 24px;
}

.prev-button {
  left: 5px;
}

.next-button {
  right: 5px;
}

.TopanchorList::-webkit-scrollbar {
  display: none;
}


.prev-button,
.next-button {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.1) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
  border-radius: 12px;
  color: white;
  font-weight: 500;
  box-shadow: 1px 1px 3px 1px rgb(81, 81, 81);
}





.prev-button:focus,
.next-button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3);
}

.prev-button:hover,
.next-button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px;
}

.prev-button:active,
.next-button:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
}

@media(max-width: 550px) {
  .slider-button {
    &.prev-button {
      left: -15px;
    }

    &.next-button {
      right: -15px;
    }
  }

  #FormC {
    margin-bottom: 20px;
  }

  .dataRoomViewPortfolio .ant-collapse-content {
    overflow-x: scroll;
    background-color: #212121;
  }

  .dataRoomViewPortfolio .ant-collapse-content .ant-collapse-content-box {
    min-width: 550px;
  }

  .marketLandscapeCard {
    padding: 0 !important;
  }
}

.portFolio_gallery {
  border-radius: 10px;
  background-color: rgba(223, 221, 221, 0.1);
  border: 1px solid lightgray;
  padding: 10px 5px 10px 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.galleryCross {
  position: absolute;
  // left: 10px;
  // position: absolute;
  top: 50%;
  right: 10%;
  // left: 100%;
  // transform: translate(-5%, -50%);
}

.doc_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 12px;
  align-items: center;

  .dwnlddoc {
    &:hover {
      color: grey;
    }
  }

  .savedoc {
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
      color: #003C9F;
    }
  }
}

// Lightbox

.lightBoxModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  max-height: 0;
  transition: .3s all ease;

  &.open {
    opacity: 1;
    visibility: visible;
    max-width: 100%;
    max-height: 100%;
    transition: .3s all ease;
  }

  .bg-light-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #171717f8;
    opacity: 0;
    transition: .3s all ease;
  }

  &.open {
    .bg-light-box {
      opacity: 1;
      transition: .3s all ease;
    }
  }

  .container-xxl {
    position: relative;
    padding-top: 20px;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .close-btn-lightbox {
      position: absolute;
      top: 30px;
      right: 20px;
      background-color: #171717;
      opacity: .7;
      cursor: pointer;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .4s all ease;
      z-index: 100;

      &:hover {
        opacity: 1;
        transition: .4s all ease;
      }
    }

    .thumbnailbar {
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      margin: auto;
      padding: 20px 20px 0;
      transition: .7s all ease;
      max-height: 0;

      .shwmrebtn {
        cursor: pointer;
        color: #fff;
        width: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: .7s all ease;
        opacity: 1;
        visibility: visible;
        overflow: hidden;
        position: absolute;
        top: -80px;
        left: 0;
        right: 0;
        max-height: fit-content;
      }

      .slider_box_thumb {
        opacity: 0;
        visibility: hidden;
        transition: .7s all ease;
      }

      &.show {
        background-color: #171717f8;
        width: 100%;
        transition: .7s all ease;
        max-height: 100%;

        .slider_box_thumb {
          opacity: 1;
          visibility: visible;
          transition: .7s all ease;
        }

        .shwmrebtn {
          transition: .7s all ease;
          opacity: 0;
          visibility: hidden;
          max-height: 0;
        }
      }
    }
  }

}

// NEW STYLING

.portfolio-main {
  .title-cont {
    background-color: #010c20;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: white;
  }

h1, h2, h3 ,h4 ,h5 ,h6 ,p {
    color: white;
  }

h1, h2, h3 ,h4 ,h5 ,h6 ,p {
    color: white;
  }

  .videos-sect-port {
    background-color: #001331;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    color: #fff;
    text-align: center;

    h2 {
      color: #fff;
      margin-bottom: 50px;
    }

    h3 {
      text-align: center;
      color: #fff;
    }

    .slick-prev {
      top: unset !important;
      bottom: -30px !important;
      left: calc(50% - 100px) !important;

      &::before {
        content: '\f053' !important;
        font-family: 'Font Awesome 6 Free' !important;
        font-weight: 900 !important;
      }
    }

    .slick-next {
      top: unset !important;
      bottom: -30px !important;
      right: calc(50% - 100px) !important;

      &::before {
        content: '\f054' !important;
        font-family: 'Font Awesome 6 Free' !important;
        font-weight: 900 !important;
      }
    }
  }

  .highlight-cont {
    background-color: #010c20;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }
  }

  .overview-cont {
    background-color: #001331;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    >* {
      color: #fff !important;
    }

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
      margin-bottom: 16px;
      text-align: center;
    }

    .slider-overview {
      .slick-slide {
        padding: 12px;
      }

      .slick-dots {
        bottom: 65px !important;
        text-align: left !important;
        padding: 0 0 0 20px !important;

        li {
          margin: 0 2px !important;

          &.slick-active {
            button {
              &::before {
                opacity: 1 !important;
                font-size: 10px !important;
                color: #00b8ff !important;
              }
            }
          }

          button {
            &::before {
              opacity: 1 !important;
              font-size: 10px !important;
            }
          }
        }
      }

      .slick-prev {
        left: 20px !important;
        top: 45% !important;

        &::before {
          opacity: 1 !important;
          transition: .3s all ease;
          font-size: 26px !important;
        }

        &:hover {
          transition: .3s all ease;

          &::before {
            color: #00b8ff !important;
          }
        }
      }

      .slick-next {
        right: 20px !important;
        top: 45% !important;

        &::before {
          opacity: 1 !important;
          transition: .3s all ease;
          font-size: 26px !important;
        }

        &:hover {
          transition: .3s all ease;

          &::before {
            color: #00b8ff !important;
          }
        }
      }

      .glry-img-cont {
        max-width: 100%;
        position: relative;
        border-radius: 14px;
        height: 260px;

        img {
          width: 100%;
          height: 260px;
          border-radius: 14px;
          object-fit: cover;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 260px;
          border-radius: 14px;
          background: linear-gradient(to top, rgba(0, 119, 255, 0.904) -10%, transparent 60%);
        }
      }
    }
  }



  .gallery-cont {
    background-color: #010c20;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
      margin-bottom: 16px;
      text-align: center;
    }

    .gallry_text {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 25px 40px;
      margin-top: 70px;

      @media (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 40px;
      }

      .gallry_img {
        width: 100%;
        max-width: 540px;
        height: 300px;
        border-radius: 25px;
      }

      .glry_txt {
        flex: 1;

        .glry_h2 {
          font-size: 42px;

          @media (max-width: 992px) {
            font-size: 34px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .media-cont {
    background-color: #001331;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
      margin-bottom: 16px;
      text-align: center;
    }

    .media-imgs-cont {
      display: flex;
      justify-content: center;
      gap: 20px 30px;
      flex-wrap: wrap;

      .media-logo {
        max-width: 120px;
        object-fit: scale-down;
      }
    }
  }

  .team-perks-cont {
    background-color: #010c20;
    padding: 80px 0 90px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 90px 0;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
      gap: 60px 0;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
      gap: 40px 0;
    }

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
      margin-bottom: 16px;
      text-align: center;
    }

    .team-card {
      width: 100%;
      min-height: 520px;
      border-radius: 16px;
      background-color: #171717;
      box-shadow: 0 0 2px 1px #ddd;

      >img {
        border-radius: 16px;
        width: 100%;
        aspect-ratio: 1/1;
        // max-height: 320px;
        background-color: #010c20;
      }

      .team-content {
        padding: 20px;
        background-color: #171717;
        border-radius: 0 0 16px 16px;

        h5 {
          color: #ccc;
          font-weight: 400;
        }

        h4 {
          color: #00b8ff;
          font-weight: 500;
          font-size: 22px;
        }

        p {
          color: #fff;
          margin: 10px 0 0;
        }
      }
    }


  }

  .inv-perks-cont {
    h2 {
      margin-bottom: 40px;
    }

    background: linear-gradient(330deg, #033BB0 5%, rgba(40, 139, 255, 0) 95%);
    padding: 30px !important;
    border-radius: 20px;

    .inv-perks-main {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      flex-direction: column;

      p {
        color: white;
      }

      .invt-perks {
        .inv-flex {
          display: flex;
          gap: 16px;
          align-items: center;
          margin-bottom: 6px;

          >img {
            width: 50px;
            height: 50px;
          }

          h5 {
            font-size: 20px;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
  }

  .use-proceed-cont {
    background-color: #001331;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
      margin-bottom: 16px;
      text-align: center;
    }

    h4 {
      font-size: 20px;
      color: #fff;
    }
  }

  .market-lands-cont {
    background: transparent;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    h2 {
      color: #fff;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .risk-disc-cont {
    background-color: #0013318f;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    h2 {
      color: #fff;
      margin-bottom: 24px;
    }
  }

  .dataroom-cont {
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    background-color: #171717ad;

    h2 {
      color: #fff;
      margin-bottom: 24px;
      text-align: center;
    }

    .faqs-asked {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 26px;

      .faq-accordion {
        transition: .8s all ease;

        h4 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
          padding: 14px 20px;
          background-color: #1a1a1a;
          margin-bottom: 1px;
          border-radius: 14px;

          span {
            transform: rotate(45deg);
            transition: .3s all ease;
          }
        }

        .faq-content {
          padding: 0px 20px;
          background-color: #4e4e4e;
          transition: .8s all ease;
          height: 100%;
          max-height: 0px;
          visibility: hidden;
          overflow: hidden;
          border-radius: 0 0 14px 14px;

          p {
            color: #fff;
            margin-bottom: 0;
          }
        }

        &.act {
          h4 {
            background-color: #4e4e4e;
            transition: .3s all ease;
            border-radius: 14px 14px 0 0;

            span {
              transform: rotate(0deg);
              transition: .3s all ease;
            }
          }

          .faq-content {
            transition: 1s all ease;
            max-height: 460px;
            visibility: visible;
            padding: 14px 20px;
          }
        }

        &:hover {
          transition: .3s all ease;

          h4 {
            background-color: #4e4e4e;
          }
        }
      }
    }
  }

  .reviews-cont {
    background-color: #0013318f;
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    h2 {
      color: #fff;
      margin-bottom: 24px;
      text-align: center;
    }

    .slick-slide {
      padding: 10px;
    }

    .slick-prev {
      top: unset !important;
      bottom: -30px !important;
      left: calc(50% - 100px) !important;

      &::before {
        content: '\f053' !important;
        font-family: 'Font Awesome 6 Free' !important;
        font-weight: 900 !important;
      }
    }

    .slick-next {
      top: unset !important;
      bottom: -30px !important;
      right: calc(50% - 100px) !important;

      &::before {
        content: '\f054' !important;
        font-family: 'Font Awesome 6 Free' !important;
        font-weight: 900 !important;
      }
    }

    .review-box {
      background-color: #171717;
      border-radius: 20px;
      box-shadow: 0 0 2px 1px #ddd;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      h4 {
        color: #fff;
      }

      p {
        color: #fff;
      }

      .ath-box {
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 16px;
          width: calc(100% - 50px);
        }

      }

      .reviewer-info {
        display: flex;
        gap: 14px;

        img {}

        .ath-info {
          width: calc(100% - 65px);

          p {
            margin-bottom: 0;
          }

          .ant-rate {
            color: #00b8ff !important;
          }
        }
      }
    }
  }

  .asked-cont {
    padding: 80px 0 90px;

    @media(max-width: 992px) {
      padding: 40px 0 50px;
    }

    @media(max-width: 550px) {
      padding: 25px 0 30px;
    }

    background-color: #171717ad;

    h2 {
      color: #fff;
      margin-bottom: 40px;
      text-align: center;
    }

    p {
      color: #fff;
    }

    .faqs-asked {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 26px;

      .faq-accordion {
        transition: .8s all ease;

        h4 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
          padding: 14px 20px;
          background-color: #1a1a1a;
          margin-bottom: 1px;
          border-radius: 14px;

          span {
            transform: rotate(45deg);
            transition: .3s all ease;
          }
        }

        .faq-content {
          padding: 0px 20px;
          background-color: #4e4e4e;
          transition: .8s all ease;
          height: 100%;
          max-height: 0px;
          visibility: hidden;
          overflow: hidden;
          border-radius: 0 0 14px 14px;

          p {
            color: #fff;
            margin-bottom: 0;
          }
        }

        &.act {
          h4 {
            background-color: #4e4e4e;
            transition: .3s all ease;
            border-radius: 14px 14px 0 0;

            span {
              transform: rotate(0deg);
              transition: .3s all ease;
            }
          }

          .faq-content {
            transition: 1s all ease;
            max-height: 300px;
            visibility: visible;
            padding: 14px 20px;
          }
        }

        &:hover {
          transition: .3s all ease;

          h4 {
            background-color: #4e4e4e;
          }
        }
      }
    }
  }
}

@media screen and (min-width : 375px) and (max-width:425px) {
  .portfolio-main .gallery-cont .gallry_text .gallry_img {
    object-fit: cover !important;
  }
}

@media screen and (min-width : 375px) and (max-width:900px) {
  .portfolio-main .overview-cont .slider-overview .glry-img-cont img {
    object-fit: cover !important;
  }
}

.switchOff {
  width: 90%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  color: #fff;
  padding: 10px;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.switchOff h5 {
  margin-left: 10px;
  margin-top: 5px;
}


.switchOn {
  border: 1px solid lightGray;
  width: 90%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  background: lightgreen
}

// Review Section 

.review-text {
  font-style: italic;
  margin-top: 20px;
}

.review_bx {
  position: relative;
}

.quote1 {
  font-size: 35px;
  font-weight: bold;
  color: rgb(45, 95, 231);
  position: absolute;
  top: -10px;
  left: -16px;
}

.quote2 {
  font-size: 35px;
  font-weight: bold;
  color: rgb(45, 95, 231);
  position: absolute;
  bottom: -10px;
  right: -16px;
  line-height: 0px;
}

.piechart {
  max-height: 400px !important;
}

// End of Review Section


.instaicon {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}

.bg-img-portfolio {
  // background-image: url('../../images/bgimgLogin.jpg');
  background-image: url('../../images/bgimgLogin.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  width: 100%;
  // overflow: hidden;
  padding-top: 95px;
  // height: 100vh;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background: linear-gradient(to bottom, rgba(0 0 0 / 50%), black 100%), linear-gradient(to bottom, transparent 0%, black 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.TopanchorList {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  color: white;
  white-space: nowrap;
  width: 90%;
  margin: 0 auto;
  overflow-x: scroll;

  @media(max-width: 550px) {
    padding: 16px;
  }
}

.topAnchorListWrapper {
  background: linear-gradient(180deg, #003C9F 50%, rgba(0, 60, 159, 0) 100%);
  backdrop-filter: blur(2.00278px) !important;
  // overflow-x: scroll;
  width: 100%;
  grid-area: nav;
}

.topAnchorListWrapperScroll {
  background: linear-gradient(180deg, #003C9F 50%, rgba(0, 60, 159, 0)100%);
  backdrop-filter: blur(2.00278px) !important;
  // overflow-x: scroll;
  width: 100%;
  grid-area: nav;
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
  z-index: 50;
}


.portfolioVideoSlider {
  display: flex !important;
  justify-content: center;
}

.topAnchorListWrapper::-webkit-scrollbar {
  width: 5px !important;
  /* width of the entire scrollbar */
}

.topAnchorListWrapper::-webkit-scrollbar-track {
  background-color: none;
  /* color of the tracking area */
}

body .topAnchorListWrapper::-webkit-scrollbar-thumb {
  background-color: #003c9f4f;
  /* color of the scroll thumb */
  width: 5px;
  -webkit-width: 5;
  scrollbar-width: 5px
}

.fundraiseDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-width: 200px;
  max-width: 300px;
  width: 260px;

  img {
    width: 40px;
    height: 40px;
  }

}

.company_high {
  background: linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);
  border-radius: 25.6258px;
  margin-top: 1.5rem;
  padding: 1rem;
  width: 100%;
}


.company_high:nth-child(even) .companyHighCard .highlightContentWrapper {
  display: flex;
  align-items: end !important;
  text-align: right;

}

.highlightContentWrapper {
  box-shadow: none !important;
  border: none;
}


.highlightDigit {
  color: #3A84FF;
  font-weight: bold;
}


.overViewDesc p {
  color: white !important;
}


.ourTeamCardBody .ant-card-body {
  padding: 10px !important;
  background: linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ourTeamCardBody {
  border-radius: 10px;
  box-shadow: 1px 1px 3px 2px #00000054;
  border: 1px solid;
  border-color: black;
}

.ourTeamCardBody .ant-card {
  border-radius: 10px !important;
  border: none;
  background: linear-gradient(0deg, #171717, #171717), #FFFFFF;
  height: 100%;

}

.ourTeamCardBody:hover .ant-card {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;


}

.ourTeamCardBody:hover .ant-card-body,
.ant-card {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;


}

.teamCardDesc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ourTeamCardBody .ant-card-cover {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

}

.h4.ant-typography {
  font-family: "sansation" !important;
}

.investorPerksDiv {
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(320.65deg, rgba(0, 96, 255, 0.8) -67.7%, rgba(40, 139, 255, 0) 79.29%);
  border-radius: 25px;
  color: white;
  padding: 1.5rem
}

.investorPerksDiv .ant-row div p {
  color: white !important;
}


.connectCompanyBtn {

  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
  border-radius: 12px !important;
  color: white !important;
}

.videoArrowCenter {

  font-size: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  margin-right: 5px;

}

.playIcon {
  height: 70px;
  width: 70px;
  border-radius: 100px;
  background-color: #00000070;
  padding: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

}

.videoSliderImg {
  object-fit: cover;
  width: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  max-width: 100%;
  aspect-ratio: 3/2;
  border-radius: 10px;
  filter: opacity(0.6) blur(1.5px);
  position: relative;

}

#investor_slider_sec {
  .slick-slider {
    .slick-slide {
      >div {
        padding: 0 10px;
      }
    }
  }
}

.gallery_sec {
  max-width: 100%;

  .slick-slider {
    width: 100%;

    .slick-slide {
      >div {
        padding: 0 10px;
      }
    }
  }
}

.playIcon:hover {
  background-color: black;
}

.marketLandscapeCard {
  background: linear-gradient(210.71deg, rgba(0, 96, 255, 0.8) -237.18%, rgba(40, 139, 255, 0) 59.74%), #171717 !important;
  border-radius: 17.5275px !important;
  padding: 0 2rem 0 2rem !important;
  box-shadow: 0px 4px 17px 3px #011429 !important;
  -webkit-box-shadow: 0px 4px 17px 3px #011429 !important;
  -moz-box-shadow: 0px 4px 17px 3px #011429 !important;


  border: 1.2px solid;

  border-image-source: linear-gradient(203.04deg, rgba(255, 255, 255, 0) 1.87%, rgba(255, 255, 255, 0.8) 22.79%, rgba(255, 255, 255, 0.8) 35.63%);


}

.textEditorInnerData p {
  color: white !important;
}

.marketLandScapeViewPortfolio {
  background: linear-gradient(94.76deg, rgba(5, 88, 226, 0.4) -19.26%, rgba(5, 88, 226, 0) 81.38%);
  border-radius: 25px;
  padding: 1rem;
  margin-top: 3rem;
}

.fillingInViewPortfolio {
  border-radius: 10px;
  backdrop-filter: blur(1px) saturate(115%);
  -webkit-backdrop-filter: blur(1px) saturate(115%);
  background-color: rgba(122, 128, 147, 0.13);
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 15px;
  display: flex;
  gap: 16px;
  max-width: 320px;

  >img {
    width: 70px;
    object-fit: scale-down;
  }

  a {
    display: block;
    width: fit-content;
    max-width: calc(100% - 90px);
    color: #00adee;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-transform: uppercase;
  }

  .filing_cont {
    width: calc(100% - 90px);
    color: #fff;

    >* {
      color: #fff;
    }

    p {
      margin-bottom: 5px;
    }

    a {
      display: block;
      width: fit-content;
      max-width: calc(100% - 120px);
      color: #00adee;
      text-decoration: underline;
      text-underline-offset: 5px;
      text-transform: uppercase;
    }
  }
}

.dataRoomViewPortfolio .ant-collapse-header {
  background: rgba(78, 78, 78, 1);
  color: white;
  border-radius: 15px !important;

}

.dataRoomViewPortfolio .ant-collapse-content .ant-collapse-content-box {
  background: #17181B !important;
  color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

}

.dataRoomViewPortfolio .ant-collapse-content {
  border-radius: 15px !important;
  border: none !important;
}

.dataRoomViewPortfolio .ant-collapse {
  background-color: transparent !important;
  border: none !important;
  // border-radius: 15px !important;

}

.ant-collapse-item {
  border: none !important;
}

.dataRoomTable th {
  color: white !important;
  font-weight: 400 !important;
  background-color: transparent !important;
}

// .dataRoomTable tbody tr td{
//   color: white !important;
//   background-color: gray !important;
//   border-radius: 15px;
//   padding: .3rem;
//   margin-top: 10px;
// }

.dataRoomTable tbody tr td a h4,
.dataRoomTable tbody tr td span {
  color: white !important;
  background-color: gray !important;
  border-radius: 15px;
  padding: .5em;
  margin-top: 10px;
  font-weight: 600;
}

.dataRoomTable tbody tr td a h4 {
  margin-bottom: 5px !important;
  margin: -5px 0 0 0 !important;
  padding: 5px !important;
  border-radius: 20px !important;
  font-size: 17px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: none !important;
}

.reviews .slick-slide:hover .review-Wrapper {
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 20px;
  text-align: center;
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-radius: 30px;
  min-height: 200px;
  color: white;
  border: 1px solid white;
  margin-left: 2em;

}


.reviews .slick-slide .review-Wrapper {
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  padding: 20px;
  color: white;
  text-align: center;
  background: linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-radius: 30px;
  margin-left: 2em;
  height: 250px;
}

.progressRoundBar .ant-progress-inner {
  height: 80px !important;
  width: 80px !important;

}


.roundBarWrapper {
  height: 150px;
  background-color: #171717;
  border-radius: 10px;
  // border: 1px solid ;
  // border-color: lightgray lightgray white white;
  height: 150px;
  background-color: #171717;
  border-radius: 10px;
  border: 1px solid;
  border-color: lightgray lightgray white white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  gap: 1rem;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  box-shadow: 1px 1px 5px 1px #0000009c;
}

.roundBarWrapper:hover {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #171717, #171717), #FFFFFF;
  border-radius: 9.90108px;
}

.gradientBorder {

  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

}

.proceedInvestTitle {
  letter-spacing: 1.5px;
  font-family: "sansation";
  line-height: 10px;
  font-size: 17px;
}

.ourTeamCardBody .ant-card .ant-card-bordered {
  background: black !important;
  object-fit: contain;
  border-radius: 10px;
}

.ourTeamCardBody .ant-card-cover img {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
}


// MANAGE PORTFOLIO NEW STYLING
.vertical-tabs-container .ant-tabs-tab {

  display: block;

  width:
    100%;

  margin-bottom:
    10px;
}

.vertical-tabs-container .ant-tabs-tab-btn {

  width:
    100%;

  text-align: left;
}

.vertical-tabs-container .ant-tabs-tab-content {

  margin-left:
    24px;
}



.portfolioTabsSection {

  background: rgba(255, 255, 255, 0.1);
  /* blur */

  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
}


.publishBtn {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
  border-radius: 8px !important;

  &.publishbtnportfolio {
    display: flex;
    align-items: center;
    margin: auto;
    font-size: 18px;
    height: 45px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;

      svg {
        animation: mymove 2s infinite;
        position: relative;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -65%;
      height: 45px;
      border-radius: 8px !important;
      background: linear-gradient(to right, #0a07a7e1 50%, transparent 60%);
      width: 100%;
      transition: .3s all ease;
      z-index: -1;
    }

    &:hover {
      &::before {
        left: 0;
      }
    }

    @media(max-width: 550px) {
      font-size: 16px;
    }
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
    right: 0px;
  }

  20% {
    opacity: 1;
  }

  100% {
    right: -40px;
  }
}

.viewPortfolioBtn {
  background: rgba(217, 217, 217, 0.5) !important;
  border-radius: 12.9286px !important;
  color: white !important;
}


.ant-tabs {
  padding-right: 1rem !important;

  /* Custom styles for the entire Tabs component */

}


// .vertical-tabs-container .ant-tabs-tab {
//   border: 1px solid;
//   border-color: gray gray black black;
//   background: rgba(23, 23, 23, 0.6) !important;
//   // padding: 1rem !important;
//   border: 1px solid gray gray black black !important;
//   border-radius: 10px !important;
//   color: white !important;

// }


.ant-tabs .ant-tabs-nav {
  font-family: "sansation";
  border: none !important;
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab-active {

  color: white !important;
  font-weight: bold;


}

.vertical-tabs-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  font-weight: 500;
}



.vertical-tabs-container .ant-tabs-ink-bar {
  background-color: transparent !important;
  // border: .5px solid #1A2B3A !important;
  border-radius: 10px !important;
}

.vertical-tabs-container .ant-tabs-nav {
  padding-right: .7rem;
}



.vertical-tabs-container .ant-tabs-tab {
  align-items: center;
  appearance: none;
  background: rgba(23, 23, 23, 0.6) !important;
  border: 1px solid;
  border-color: gray gray black black;
  border-radius: 10px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "sansation";
  justify-content: center;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  padding: 10px 24px 10px 24px !important;

}

.vertical-tabs-container .ant-tabs-tab:focus {
  box-shadow: #033BB0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #033BB0 0 -3px 0 inset;
}

.vertical-tabs-container .ant-tabs-tab:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #033BB0 0 -3px 0 inset;
  transform: translateY(-2px);
}


.vertical-tabs-container .ant-tabs-tab-active {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(23, 23, 23, 0.4) !important;
  border-radius: 10px !important;
  color: white !important;
  box-shadow: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #033BB0 0 3px 7px inset;
  transform: translateY(2px);
}

.ant-tabs-content-holder {
  border: none !important;
  /* Border for the content part */
  border-top: transparent;
  /* Remove the top border so that there is no overlap*/
}

.companyHighCard {
  img {
    width: 50px !important;
  }

  h1 {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
}

@media(max-width: 991px) {
  .proceedInvestTitle {
    width: 100%;
  }

  .portfolioTabsSection {
    >div {
      >div {
        &.col {
          h2 {
            font-size: 26px;
          }
        }
      }
    }
  }
}

// @media (max-width: 800px) {
//   .vertical-tabs-container .ant-tabs-tab {
//     width: fit-content;
//     margin-bottom: 0;
//   }

//   .vertical-tabs-container .ant-tabs-top>.ant-tabs-nav:before {
//     border-bottom: none !important;
//   }
// }

@media screen and (max-width : 600px) {
  .vertical-tabs-container .ant-tabs-tab {
    padding: 10px 5px 10px 5px !important
  }
}

.review-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to clamp */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-selection-placeholder {
  color: gray !important;
}


.videoSlider {
  border: 0.2px solid rgba(128, 128, 128, 0.2509803922);
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 3px 8px 0px rgba(182, 180, 180, 0.6);
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.6);
  transition: 0.3s all ease;
  height: auto !important;
  width: 300px;
  cursor: pointer;
}

.videoSlider div {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  top: 0px;
  color: white;
  font-weight: bold;
  font-family: "sansation";
  left: 0;
  padding: 10px;
  background: rgb(0 0 0 / 33%);
  width: 100%;
}


.videoSlider div h5 {
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "sansation";
}

.primaryVideoUserSide {
  border: 1px solid gray;
  border-radius: 18px;
  box-shadow: 1px 1px 7px 1px #efeff63d;
  width: "-webkit-fill-available";
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3/2;

}

.primaryVideoUserSide.vidbox {
  >div {
    height: 100% !important;

    video {
      aspect-ratio: 3/2;
      object-fit: cover;
      border-radius: 18px;
    }
  }
}

// @media (min-width:1000px) {
//   .primaryVideoUserSide {
//     height: 350px;
//   }
// }

.primaryVideoImage {
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 10px;
  filter: opacity(0.6) blur(1.5px);
  position: relative;
  aspect-ratio: 3/2;
}


.next-Pre-btn {
  width: 80% !important;
  /* right: 0; */
  /* float: right; */
  display: flex;

  justify-content: space-between;
  padding-top: 2rem;
  flex-wrap: wrap;
  margin-left: 20%;
}

.previousBtn,
.nextBtn {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
  color: #fff;
  border: 1px solid navy;
  outline: none;
  min-width: 100px;
  width: -moz-fit-content;
  width: 150px;
  height: 40px;
  padding: 4px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.4s;
  box-shadow: 1px 1px 3px px navy;
}

.previousBtn:hover,
.nextBtn:hover {
  transition: 0.4s;
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.9) -47.14%, rgba(70, 139, 255, 0) 100.19%), linear-gradient(0deg, #02004F, #02004F), rgba(287, 217, 217, 0.5);

}

.previousBtn[disabled],
.nextBtn[disabled] {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.1) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.1);
  opacity: 0.5;
}




.slider-container {
  width: 300px;
  /* Adjust the width as per your requirements */
  overflow: hidden;
  position: relative;
}

.slider {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slider a {
  flex: 0 0 auto;
  padding: 8px;
  background-color: #ccc;
  margin-right: 10px;
  color: #000;
  text-decoration: none;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  z-index: 10;
  background: none !important;
  box-shadow: none !important;
  font-size: 24px;
}

.prev-button {
  left: 5px;
}

.next-button {
  right: 5px;
}

.TopanchorList::-webkit-scrollbar {
  display: none;
}


.prev-button,
.next-button {
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.1) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
  border-radius: 12px;
  color: white;
  font-weight: 500;
  box-shadow: 1px 1px 3px 1px rgb(81, 81, 81);
}





.prev-button:focus,
.next-button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3);
}

.prev-button:hover,
.next-button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px;
}

.prev-button:active,
.next-button:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
}

@media(max-width: 550px) {
  .slider-button {
    &.prev-button {
      left: -15px;
    }

    &.next-button {
      right: -15px;
    }
  }

  #FormC {
    margin-bottom: 20px;
  }

  .dataRoomViewPortfolio .ant-collapse-content {
    overflow-x: scroll;
    background-color: #212121;
  }

  .dataRoomViewPortfolio .ant-collapse-content .ant-collapse-content-box {
    min-width: 550px;
  }

  .marketLandscapeCard {
    padding: 0 !important;
  }
}

.portFolio_gallery {
  border-radius: 10px;
  background-color: rgba(223, 221, 221, 0.1);
  border: 1px solid lightgray;
  padding: 10px 5px 10px 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.galleryCross {
  position: absolute;
  // left: 10px;
  // position: absolute;
  top: 50%;
  right: 10%;
  // left: 100%;
  // transform: translate(-5%, -50%);
}

.doc_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 12px;
  align-items: center;

  .dwnlddoc {
    &:hover {
      color: grey;
    }
  }

  .savedoc {
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
      color: #003C9F;
    }
  }
}

.textArea {
  border-radius: 8px !important;
  resize: none;
  outline: 1px solid lightgray;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}



.overViewHtmlContent>h1,
.overViewHtmlContent>h2,
.overViewHtmlContent>h3,
.overViewHtmlContent>h4,
.overViewHtmlContent>h5,
.overViewHtmlContent>h6,
.overViewHtmlContent>p {
  color: white !important;
}


.market_input_div {
  position: relative;

  input {
    padding: 6px 20px 5px 12px !important;
  }

  span {
    &.dollar-sign {
      position: absolute;
      top: 4px;
      right: 10px;
      margin-top: 0px !important;
      color: #171717 !important;
      background-color: #fff !important;
      font-size: 18px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

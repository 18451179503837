#form {
  max-width: 500px;
  max-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  position: relative;
  margin: 5rem auto;
}

.creditFormBody {
  background: #171717;
  border-radius: 10px;
  padding: 2.5rem 1rem 1rem;
  box-shadow: 3px 3px 40px #545454e8;
}

#form .input-grp,
#form select {
  margin: 0 0 1rem 0;
  padding: 0.5rem 0.25rem;
  font-size: 15px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  width: 100%;
}

#form h4 {
  color: #fff;
  font-family: 'sansation';
  font-weight: bold;
  font-size: .90rem;
}

.mt {
  margin-top: 8rem;
}

#form .input-grp .input-container {
  position: relative;
  font-size: 1rem;
  color: #333;
}

#form .input-grp,
#form input {
  margin: 0 0 1rem 0;
  padding: 0.5rem 0.25rem;
  font-size: 15px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  width: 100%;
  color: black;
}

#form .input-grp {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

#form .creditCardSubmit {
  width: 100%;
  padding: 0.7rem 0.5rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  border-radius: 7px;
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
  color: #FFF;
  letter-spacing: 1px;
  font-size: 1.15rem;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

// #form .creditCardSubmit:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 0;
//   background-color: #3b2dd2fa;
//   z-index: -1;
// }

// #form .creditCardSubmit:hover:before {
//   width: 100%;
//   transition: .2s all;
// }

#creditCardNumber {
  flex: 1;
  word-break: break-all;
  letter-spacing: 4px;
  font-size: 0.5rem !important;
  color: #fff !important;
}

#form .creditCardButtonDisabled {
  opacity: 50%;
  width: 100%;
  padding: 0.7rem 0.5rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  border-radius: 7px;
  background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
  color: #FFF;
  letter-spacing: 1px;
  font-size: 1.15rem;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

#card {
  height: 250px;
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../images/cardBg.webp");
  // background-image: url("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-position: center;
  color: #eee;
  filter: drop-shadow(1px 3px 10px #222);

  animation: fadeIn .2s linear 1;
}

.dashboard_card #card {
  height: 250px !important;
}

.dashboard_card .creditFormBody {
  padding: 1rem !important;
}

@keyframes fadeIn {
  from {
    top: -150px;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#card .header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}

#card .sticker {
  width: 50px;
  height: 40px;
  background-image: url("https://images.pexels.com/photos/728471/pexels-photo-728471.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500");
  background-position: center;
  background-size: cover;
  object-fit: contain;
  border-radius: 5px;
}

#card .logo {
  max-width: 60px;
  height: auto !important;
  object-fit: contain;
  margin-bottom: 0 !important;
}

#card .body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  height: 60%;
  gap: 10px;
}

#card .footer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}


@media (max-width:"700px") {
  #form .input-grp {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  #card {
    top: 0rem !important;
  }

  .input-container.mt {
    margin-top: 16rem !important;
  }

  #form .input-grp,
  #form input {
    margin: 0 0 0.3rem 0;
  }
}

@media(max-width: 550px) {
  .creditcard {
    padding: 0 !important;
  }

  #form {
    min-height: 200px;
    margin: 0 !important;
    padding: 0px;

    h4 {
      font-weight: 400;
      font-size: 14px;
    }
  }

  #card {
    padding: 10px 10px 5px;

    h5 {
      font-weight: 500;
      font-size: 14px;
    }

    .logo {
      max-width: 45px;
    }
  }
}
.fCatbtn {
    font-size: 0.8rem;
    text-decoration: none;
    display: inline-block;
    color: $white-color;
    border: 1px solid rgb(173, 170, 170);
    background-color: #007ce967;
    border-radius: 15px;
    padding: 6px 12px;
    margin: 5px 5px;

    &:hover {
        background-color: $black-color;
    }
}

.footer_nav {
    list-style: none;
}

.footer_nav li {
    color: $white-color;
    font-size: large;
}

.footer_nav li a {
    cursor: pointer;
}

.email_footer {
    font-size: small;

    &:hover {
        color: #000;
    }
}

.main_footer {
    background-color: $primary-color;
}

.second_footer {
    background-color: black;
    text-align: center;
    padding: 5px;
    // position: absolute;
    width: 100%;
    bottom: 0;
}


.second_footer p {
    margin-top: 10px;
}

.second_footer ul li:hover {
    color: blue;
}

.second_footer hr {
    border-bottom: 1px solid gray;
    width: 75%;
    margin: 0 auto;
}

.backtotop {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    right: 20px;
    border-radius: 10px;
    width: 40px;
    height: 50px;
    background-color: rgb(8, 0, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-size: 16px !important;

    &:hover {
        color: #fff;
        background-color: rgb(6, 0, 194);
    }
}

@media (min-width : 768px) {
    .footerLogo {
        width: 70%;
    }
}

.footerLogo {
    width: 30%;
}


.bottomConcentDiv {
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.bottomConcentDiv a {
    color: #6c757d;
    font-size: 0.8rem;
    margin-top: .5rem;
    font-weight: 400;
    font-family: 'sansation';

    &hover {
        color: blue;
        text-decoration: underline;
    }

}
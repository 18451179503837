.payments_main_box {
  // background-color: #171717;
  border-radius: 20px;
  margin: 20px;
  padding: 20px 26px;
  backdrop-filter: blur(1px) saturate(120%);
  -webkit-backdrop-filter: blur(1px) saturate(120%);
  background-color: rgb(1 27 51 / 51%);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

#paymentsPage {
  margin-top: 1rem;
}

.toggle_box {
  border-radius: 20px;
  background-color: black;
  margin: 20px;
  color: white;
  padding: 10px 0;
}

.ant-picker-range-separator,
.ant-picker-suffix {
  color: white !important;
}

.toggle_box .form-check-input {
  border: 1px solid #000 !important;
}

.toggle_box .common_toggle del {
  font-size: 14px;
}

.update_form_level_two .form-check label {
  color: #8c9092;
}

.form-check.agree label {
  color: #000;
}

.paymentHead {
  // color: #fff !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

@media (max-width: 550px) {
  .paymentHead {
    font-weight: 700 !important;
    font-size: 1.1rem !important;
  }
}

.text-18 {
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.descpay {
  font-size: 22px;
  line-height: 32px;

  @media (max-width: 992px) {
    font-size: 18px;
  }
}

.charge_rate {
  font-size: 20px;
}

@media screen and (min-width: 1100px) and (max-width: 1500px) {
  .paymentHead {
    font-size: 1.2rem !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 3000px) {
  .paymentHead {
    font-size: 2rem !important;
  }

  .smedate {
    font-size: 22px;
  }
}

.common_dateRange {
  // border-left: .5px solid lightgray;
  // border-right: .5px solid lightgray;
  border-radius: 10px;
  backdrop-filter: blur(1px) saturate(115%);
  -webkit-backdrop-filter: blur(1px) saturate(115%);
  background-color: rgba(122, 128, 147, 0.13);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);

  padding: 15px;
}

.paymentRow {
  display: grid;
  grid-auto-flow: column;
  gap: 2%;
}

//   .paymentRow div{
//     border: 1px solid red;
//   }

#paymentsPage .PaymentsPage_wrapper {
  // margin: 2rem auto;
  // border: 1px solid blue;
}

.ant-picker.ant-picker-range {
  height: 40px !important;
}

// .tableBg {
//     border-left: 13px solid rgb(1 114 214);
//     border-radius: 10px;
//     height: 100%;
//     padding-bottom: 5px;
//     // position: absolute;
//     // left: 0;
//     // top: 0;
// }

.tableBg .MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  opacity: 0 !important;
}

.tableBg .MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  color: #666873 !important;
}

.payment_right_side {
  padding: 0.7rem;
}

.payment_desc_text {
  margin-top: 1rem !important;
  background-color: #171717;
  border-radius: 10px;
  padding: 15px;
  color: white;
}

.ant-picker-range {
  border-radius: 12px !important;
  padding: 10px;
  border: 1px solid gray;
  background: none !important;
}

#paymentsPage h4 {
  color: white;
  font-family: "sansation";
  font-weight: 600;
}

.mt-1 {
  margin-top: 1rem;
}

.payButton {
  display: flex;
  width: 100%;
  gap: 10px;
}

.payButton button {
  background: linear-gradient(261.66deg,
      rgba(0, 96, 255, 0.8) -47.14%,
      rgba(40, 139, 255, 0) 179.19%),
    linear-gradient(0deg, #02004f, #02004f), rgba(217, 217, 217, 0.5) !important;
  // padding: 10px 20px 10px 20px;
  border: none;
  color: white !important;
  font-weight: 500;
  outline: 1px solid blue;
  border-radius: 4px;
}

.promoButton button {
  background: linear-gradient(261.66deg,
      rgba(0, 96, 255, 0.8) -47.14%,
      rgba(40, 139, 255, 0) 179.19%),
    linear-gradient(0deg, #02004f, #02004f), rgba(217, 217, 217, 0.5) !important;
  // padding: 10px 20px 10px 20px;
  border: none;
  color: white !important;
  font-weight: 500;
  outline: 1px solid blue;
  border-radius: 4px;
}

.btn-text-left {
  text-align: right;
}

@media (max-width: 600px) {
  .tableBg {
    height: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
  }
}

@media (max-width: 768px) {
  .paymentRows {
    padding: 0 !important;
  }
}

// =========================================  PAYMENT HISTORY SCSSS ==========================================================

#paymentsHistory {
  border-radius: 10px;
  margin-bottom: 5rem;
}

// rgb(207 231 237)/
#paymentsHistory .topsection {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem 1rem 1rem;
}

#paymentsHistory .topsection h3 {
  font-weight: bold;
  font-family: "sansation";
}

.tableContainer {
  width: 97% !important;
  border-radius: 15px !important;
  margin: 1rem auto;
  background-color: rgb(238 244 250) !important;
}

.topSectionImgWrapper {
  width: 100%;
  // background: linear-gradient(180deg, rgba(0, 19, 42, 0) 33.84%, #00203A 97.79%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url("../../../images/paymentBg.jpg");
  position: relative;
  background-size: cover;
  min-height: fit-content;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 20px 40px;
  text-align: center;
}

.cndcard {
  h1 {
    color: #fff;
    margin-bottom: 10px;
  }

  h6 {
    font-weight: 700;
  }
}

.cndimg {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.mapbox {
  padding: 20px 20px 0;
  // height: 280px !important;
  // @media (min-width: 1620px) {
  //   min-height: 340px !important;
  // }
}




.mapname {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;

  @media screen and (min-width : 600px) and (max-width :992px) {

    .mapbox,
    .remaindays,
    .chartcard {
      min-height: 400px;
    }
  }

  @media screen and (min-width : 400px) and (max-width :600px) {

    .mapbox,
    .remaindays,
    .chartcard {
      min-height: 400px;
    }
  }
}

.chartcard {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px !important;
  background-color: #171717 !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: black gray gray black !important;
  height: 250px !important;

  @media (min-width: 1620px) {
    min-height: 340px !important;
  }

  .chrtbox {
    width: 100% !important;
    height: 210px !important;

    @media (min-width: 1620px) {
      min-height: 300px !important;
    }
  }
}

.remaindays {
  padding: 30px 00px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: #171717;
  color: #fff;
  width: 100%;
  height: 250px !important;
  border-style: solid;
  border-width: 1px;
  border-color: black gray gray black;

  @media (min-width:1620px) {
    height: 340px !important;

  }

  h3 {
    color: #fff;
    font-size: 24px;
  }
}

.chat_box {
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius: 20px;
  background-color: #fff;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(2.8px);
  }

  >div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(217, 217, 217, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    >p {
      transform: rotate(-35deg);
      transform-origin: center center;
      font-size: 40px;
      color: #000;
      font-weight: 700;
    }
  }
}

.coming {
  font-size: 28px;
  color: #000;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 0;
}

.linkbx {
  padding: 5px 30px 30px;
}

.digibooth {
  padding: 25px;

  h3 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }
}

.blue_right_Border {
  max-width: 1400px;
  margin: auto;
}

.blue_right_Border.trans {
  width: 100%;
}

.blue_right_Border.trans {
  overflow: auto !important;
}

.schedules {
  padding: 10px 5px 5px;
  color: #fff;

  &:not(:nth-last-of-type(1)) {
    border-bottom: 1px solid #ddd;
  }

  h6 {
    font-size: 15px;
    margin-bottom: 5px;
    color: #fff;
  }

  p {
    font-size: 14px;
    margin-bottom: 0px;
    color: #fff;
  }
}

.actions_pay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 5px;
  padding: 5px 0;

  button {
    border: none;
    outline: none;
    color: #fff;
    text-align: center;
    font-weight: 500;
    border-radius: 4px;
    padding: 0px 10px;
    height: 35px;
    display: flex;
    align-items: center;

    &.repaybtn {
      background-color: green;
    }

    &.cancelbtn {
      background-color: grey;
    }
  }
}

.transactionlogs {
  .head_box {
    display: flex;
    justify-content: space-between;

    >div {
      padding: 10px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      min-height: 40px;

      &:nth-of-type(1) {
        min-width: 30px;
      }

      &:nth-of-type(2) {
        min-width: 80px;
      }

      &:nth-of-type(3) {
        min-width: 80px;
      }

      &:nth-of-type(4) {
        min-width: 120px;
      }

      &:nth-of-type(5) {
        min-width: 100px;
      }

      &:nth-of-type(6) {
        min-width: 200px;
      }

      &:nth-of-type(7) {
        min-width: 80px;
      }

      &:nth-of-type(8) {
        min-width: 110px;
      }
    }
  }

  .trans_row {
    border-radius: 10px;
    background-color: #fff;
    padding: 0 0 20px 0;

    .body_box {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      margin-top: 20px;
      border-radius: 10px;

      .expandbtn {
        svg {
          transform: rotate(0deg);
          transition: 0.3s all ease;
        }

        &.open {
          svg {
            transform: rotate(-180deg);
            transition: 0.3s all ease;
          }
        }
      }

      >div {
        color: #000;
        font-size: 14px;
        padding: 10px;
        min-height: 55px;
        flex: 1;
        text-align: center;

        &:nth-of-type(1) {
          min-width: 30px;
          flex: 0;
        }

        &:nth-of-type(2) {
          min-width: 80px;
        }

        &:nth-of-type(3) {
          min-width: 80px;
        }

        &:nth-of-type(4) {
          min-width: 120px;
        }

        &:nth-of-type(5) {
          min-width: 100px;
        }

        &:nth-of-type(6) {
          min-width: 200px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 10px 10px 0 10px;
        }

        &:nth-of-type(7) {
          min-width: 90px;
        }

        &:nth-of-type(8) {
          min-width: 140px;
        }
      }
    }

    .booking_dates {
      background-color: #fff;
      border-radius: 10px;
      visibility: hidden;
      overflow: hidden;
      margin: 0px 20px 0 20px;
      max-height: 0;
      height: fit-content;
      transition: 0.5s all ease;
      border: 1px solid #ddd;

      &.open {
        visibility: visible;
        max-height: 180px;
        transition: 0.5s all ease;
        margin: 20px 20px 0 20px;
      }

      table {
        tr {
          &:not(:nth-last-of-type(1)) {
            border-bottom: 1px solid #ddd !important;
          }
        }

        th {
          text-align: center;
          padding: 10px;

          &:not(:nth-of-type(1)) {
            border-left: 1px solid #ddd !important;
          }
        }

        td {
          padding: 10px;
          font-size: 14px;

          &:not(:nth-of-type(1)) {
            border-left: 1px solid #ddd !important;
          }
        }
      }

      >div {
        color: #000;
      }
    }
  }
}

#payment-form {
  max-width: 480px;
  margin: auto;

  label {
    color: #fff;
  }
}

@media (min-width: 1440px) {
  .topSectionImgWrapper {
    height: 30vh;
  }
}

.topSectionImgWrapper :where(h2, p) {
  font-family: "sansation";
}

.topSectionImgWrapper img {
  width: 100%;
}

.backDropBlur {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  backdrop-filter: blur(11px);
  position: relative;
  bottom: 4rem;
  border-radius: 20px;
}

.bgDropBlur {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  backdrop-filter: blur(11px);
  position: relative;
  border-radius: 20px;
}

.datebx {
  background-color: #171717 !important;
  color: #fff !important;
  border-radius: 20px;
  margin: 0px auto 0;
  width: 100%;
  max-width: 100%;
}

.datebx .rdrCalendarWrapper {
  background-color: #171717 !important;
  color: #fff !important;
  width: 100%;
  max-width: 332px;
}

.datebx .rdrDateDisplayWrapper {
  background-color: #171717 !important;
  display: none !important;
}

.datebx.dteblck .rdrDateDisplayWrapper {
  background-color: #171717 !important;
  display: block !important;
}

.datebx .rdrDayNumber span {
  color: #fff !important;
}

.datebx .rdrNextPrevButton:hover {
  background: #000 !important;
}

.datebx .rdrNextButton i {
  border-color: transparent transparent transparent #fff !important;
}

.datebx .rdrPprevButton i {
  border-color: transparent #fff transparent transparent !important;
}

.datebx .rdrMonthAndYearPickers select {
  color: #fff !important;
}

.datebx .rdrNextPrevButton {
  background-color: transparent !important;
}

@media (min-width: 1440px) {
  .backDropBlur {
    width: 70%;
  }
}

.tableContainer {
  background-color: transparent !important;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td {
  padding: 10px !important;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tableBg {
  background-color: transparent !important;
}

.tableBg.vidtable {
  background-color: #171717 !important;
}

.tableBg .MuiDataGrid-root--densityStandard {
  border: none !important;
  color: white !important;
}

.tableBg .MuiDataGrid-row {
  background: white;
  color: black;
  border-radius: 15px;
  margin: 1rem 0 0;
}

.tableBg .MuiDataGrid-row:nth-last-of-type(1) {
  margin: 1rem 0 !important;
}

.userDataTableDOwnloadBtn {
  background: linear-gradient(261.66deg,
      rgba(0, 96, 255, 0.8) -47.14%,
      rgba(40, 139, 255, 0) 179.19%),
    linear-gradient(0deg, #02004f, #02004f), rgba(217, 217, 217, 0.5);
  border-radius: 16.6259px !important;
  color: white !important;
  padding: 10px !important;
}

.userDataTableDOwnloadBtn a svg {
  margin-left: 5px;
}

.videoUploadDiv {
  filter: drop-shadow(2px 4px 6px #001d32);
  transition: 0.4s;
  animation: animation1 6s ease-out 6s infinite normal forwards;
  opacity: 60%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 200px;
    width: 100%;
  }

  @media (max-width: 767px) {
    img {
      height: auto;
    }
  }
}

.videoUploadDiv:hover {
  filter: blur(0px);
  transition: 0.4s;
  animation-play-state: paused;
  opacity: 100%;
}

@keyframes animation1 {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60% {
    transform: translateX(10px);
  }

  80% {
    transform: translateX(8px);
  }

  90% {
    transform: translateX(-8px);
  }
}

.VideoCardText .ant-card-meta-detail .ant-card-meta-title {
  color: white;
}

.videoCard .ant-card-actions {
  background-color: #171717 !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.tableBg .css-iclwpf .MuiDataGrid-row {
  display: flex;
  width: fit-content;
  break-inside: avoid;
  background: white !important;
  margin-top: 2rem !important;
  border-radius: 15px !important;
  color: black !important;
}

.tableBg .MuiDataGrid-row {
  background: white !important;
  border-radius: 10px !important;
  color: black !important;
  width: calc(100% - 12px);
}

.tableBg .MuiDataGrid-cell {
  border-bottom: 0px !important;
}

.tableBg .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.tableBg .MuiDataGrid-columnHeaders {
  border-bottom: none !important;
}

.tableBg.MuiPaper-root {
  box-shadow: none !important;
}

.tableBg .MuiDataGrid-footerContainer {
  border-top: none !important;
}

.css_iclwpf {
  border: none !important;
}

.rdrCalendarWrapper {
  border-radius: 20px !important;
}

.rdrDateDisplayWrapper {
  border-radius: 20px 20px 0 0 !important;
}

//   .videoCardIcon{
//     color: white;
//     border-top:.4px solid lightGray;
//     border-right:.4px solid lightGray;
//     border-bottom: .4px solid lightGray;
//     border-left:.4px solid lightGray;
//     height: 40px;
//     width: 40px;

//     border-radius: 10px
//   }
.ant-tabs-tab-btn {
  color: white;
}

.opacityDown {
  opacity: 50%;
  filter: blur(2px);
  cursor: not-allowed;
}

.opacityUp {
  opacity: 100%;
}

@media (max-width: 550px) {
  .cndimg {
    width: 30px;
    height: 30px;
  }

  .cndcard {
    min-width: 240px !important;

    h1 {
      color: #fff;
      margin-bottom: 10px;
      font-size: 20px;
    }

    h6 {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .mapname {
    font-size: 20px;
  }

  .mapbox {
    padding: 15px 10px 0;
  }

  .chartcard {
    padding: 5px 0;
  }

  .remaindays {
    padding: 20px 10px 0px;

    h3 {
      font-size: 20px;
    }
  }

  .coming {
    font-size: 20px;
  }

  .linkbx {
    padding: 0 20px 20px;

    a {
      min-height: auto !important;
    }
  }

  .digibooth {
    padding: 15px;

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }
  }

  .investleads {
    h3 {
      font-size: 20px;
    }
  }

  .datebx {
    h2 {
      text-align: center;
      padding: 0 10px;
      line-height: 30px;
    }

    .rdrCalendarWrapper {
      .rdrMonth {
        width: 100%;
      }

      .rdrNextPrevButton {
        margin: 0;
      }
    }
  }

  .user_table_heads {
    h3 {
      font-size: 18px;
    }

    button {
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .topSectionImgWrapper {
    h1 {
      font-size: 32px;
    }

    .descpay {
      line-height: 28px;
    }
  }
}

@media (max-width: 767px) {
  .promoButton {
    text-align: center;
    width: 100%;
  }

  .common_dateRange {
    border-top: 0.5px solid lightgray;
    border-right: none;
    border-left: none;
    padding: 13px !important;
  }

  .charge_rate {
    font-size: 16px;
  }

  #paymentsPage h4 {
    font-size: 17px;
  }

  .topSectionImgWrapper {
    h1 {
      font-size: 26px;
    }
  }
}

@media (max-width: 550px) {
  .topSectionImgWrapper {
    h1 {
      font-size: 22px;
    }

    .descpay {
      font-size: 16px;
    }
  }

  .payments_main_box {
    margin: 0 !important;
  }
}
// #companyDetailsPage{
//     height: 100vh;
// }

.company_Details_card{
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
        justify-content: center;
    align-items: center;
    

}

.company_img{
    width: 30%;
    /* border: 2px solid; */
    object-fit: cover;
    border-radius: 10px;
    margin: 0 auto;
   
}

.company_img img{
    width: 100%;
}

.first_Div_Company_details{
    width: 70%;

}

.company_Details{
    width:100%;
    border: 1px solid gray;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: 4px 7px 15px -2px rgba(0,0,0,0.61);
-webkit-box-shadow: 4px 7px 15px -2px rgba(0,0,0,0.61);
-moz-box-shadow: 4px 7px 15px -2px rgba(0,0,0,0.61);

 }

#companyDetailsPage .company_Details span {
    color: black;
    font-weight: bold;
    // margin-top: 2rem;
    font-size: 18px;
}
#companyDetailsPage .company_Details p {
    margin-left: 1rem;
    color: gray;

}
.company_Details_flex{
    display: flex;
    flex-direction: row;
}



#companyDetailsPage h1{
    text-decoration: underline;
    text-decoration-color: navy;
    text-underline-offset: 1.5rem;
    text-decoration-thickness: 4px ;
    text-align: center;
    margin-top: 7rem;
    font-weight: bold;
}





@media (max-width : 600px) {
    .company_Details_flex{
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    } 

    #companyDetailsPage h1{
        font-size: 2rem;
    }

    #companyDetailsPage .company_Details p {
        margin:0;
    }

    #companyDetailsPage .company_Details span {
        color: black;
        font-weight: bold;
        // margin-top: 2rem;
        font-size: 16px;
    }

    .company_img{
        margin: 0 auto  ;
        width: 50%;
    }

    .company_Details{
        flex-direction: column-reverse;
        padding: 2rem 1rem 1rem 1rem;
    }
}


// @import  "antd/dist/antd.css";
@import 'antd/dist/antd.min.css';

// **************** Common Partials  *******************
@import "./partails/Common/Utillies_Color";
@import './partails/Common/Utillies_Setup';
@import './partails/Common/Bottons.scss';
@import './partails/Common/Navbar';
@import './partails/Common/Counter';
@import './partails/Common/footer';
@import './partails/Common/Responsive';

// **************** Home page Partials  *******************
@import './partails/Home/Banner';
@import './partails/Home/getCard';
@import './partails/Home/Slider';
@import './partails/Home/review';

// **************** About Page Partials  *******************
@import './partails/About/Header.scss';
@import './partails/About/AboutSlider.scss';

// **************** Contact Page Partials  *******************
@import "./partails/Contact/Form";
@import './partails/Login&SignUp/LoginForm';

// **************** Profile Partials  *******************
@import './partails/userProfile/UserProfile.scss';

// **************** COmpanies Partials  *******************
@import './partails/companies/companies.scss';
@import './partails/companies/sidebar.scss';
@import './partails/companies/countdowntimer.scss';

// **************** component Partials  *******************
@import './partails/components/Video_card';

// ******************* get company user side= ***************
@import './partails/getCompanyUserSide/companyDetails';

@import './partails/Common/creditCard';

// *******************  company payments ***************


@import './partails/companies/payments/payments';
@import './partails/portFolio/portfolio.scss';
@import './partails/portFolio/manage_portfolio.scss';



.top-banner {
  min-width: 100%;
  background-image: url(../public/banner.jpg);
  background-size: cover;
  padding: 10.875rem 0 9.375rem;
}

.ovrlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffffe8;
  backdrop-filter: blur(2px);
}

.email_verification_page {
  min-width: 100%;
  background: url("../src/images/paymentBg.jpg") no-repeat top, linear-gradient(180deg, rgba(0, 19, 42) 33.84%, #00132A 97.79%);
  // background: url("../src/images/bgimgLogin.jpg") no-repeat top left / 100% 100%, linear-gradient(180deg, rgba(0, 19, 42) 33.84%, #00132A 97.79%);
  padding: 10.875rem 0 9.375rem;
  height: calc(100vh - 103px);
  min-height: 400px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font-size: 2rem;

  button {
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    font-weight: 600;
    box-shadow: 1px 2px 3px 2px rgba(128, 128, 128, 0.347);
    transition: 0.4s;
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.348);
      transition: 0.4s;
      color: white;
    }
  }

  h2 {
    color: #fff;
  }

  p {
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}



.myButton {
  /* Your CSS styles for the disabled button here */
  background-color: darkgray !important;
  color: white;
  cursor: not-allowed;
  opacity: 80%;
  border: 1px solid gray !important;
  border-radius: 10px !important;
  /* Add any other styles you want */
}

.schedule_heading {
  padding-top: 60px;
  font-size: 44px;

  @media(max-width: 991px) {
    font-size: 32px;
  }

  @media(max-width: 767px) {
    font-size: 26px;
  }

  @media(max-width: 550px) {
    font-size: 22px;
  }
}
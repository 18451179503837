.Card_hover:hover .img_card {
    fill: #ffffff;
}

.img_card {
    margin: 0 16px;
}

.card_para {
    color: #000;
}

.Card_hover {
    background-color: $white-color;
    color: $black-color;

    &:hover {
        background-color: $primary-color;
        cursor: pointer;
        color: $white-color;
    }
}

.Card_hover:hover .getBtn {
    background-color: $white-color;
    color: $secondary-color;
}

.Card_hover:hover .Card_heading {
    color: $white-color;
}

.Card_hover:hover .card_para {
    color: $white-color;
}

.Card_heading {
    font-size: 1.5rem;
    color: #000;
    margin: 1rem 0;

}

.GetStartFooter {
    margin-top: -20px;
}



// ================================home page new ==========================

.how-works .how-it-work-card {
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    border: 1px solid white;
    background: #171717;
    backdrop-filter: blur(1.73643px);
    border-radius: 20px;
    aspect-ratio: 1/1;
    max-height: 360px;

    >img {
        object-fit: scale-down;
    }

    h3 {
        font-weight: 700;
        color: #fff;
        margin-bottom: 0;
    }
}


.carousel-cell::before {
    display: block;
    text-align: center;
    content: counter(carousel-cell);
    line-height: 80px;
    color: white;
    background-color: #d3d3d35c;
}



.exploreCard_location {
    color: gray !important;
    text-transform: uppercase;
    font-size: 12px !important;
}

.exploreCard_timer {
    display: flex;
    align-items: center;
    border-top: 1px solid white;

    >p {
        color: #555 !important;
    }
}

.exploreCard_timer .show-counter {
    padding-left: 0;
}

.exploreCard_timer .show-counter .countdown-link {
    // border: 1px solid red;
    padding: 0 !important;
    gap: 0;
    font-size: 14px !important;
}


.exploreCard_timer .show-counter .countdown {
    height: 20px;
    min-width: 15px;
    background: none !important;
    gap: 0;
}




.exploreCard_timer .show-counter .countdown-link div~p {
    display: none;
}


.exploreCard_timer .show-counter .countdown p {
    font-size: 14px;
    font-weight: 500;
    color: white;
}
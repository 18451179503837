.navbar-toogle-btn:focus {
    outline: unset;
    border: unset;
    box-shadow: none;
}

.nav-link {
    font-size: 1.0rem;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: auto 10px;
    cursor: pointer;

    &:hover {
        color: #000;
    }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    right: 0px !important;
    left: auto !important;
}

.nav-link.dropdown-toggle {
    border: none !important;

    &:hover {
        border: none !important;
    }

    &:focus {
        border: none !important;
    }
}

@media (min-width : 992px) {

    .navlinkTop .nav-link {
        color: white !important;
    }

    .signUpBtnFixed {

        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(13.3px);
        border: 1px solid rgba(226, 226, 226, 0.47);
        padding: 6px 20px !important;
        font-weight: 600 !important;
        color: white !important;

    }

    .signUpBtnScroll {
        background: rgb(0 0 0 / 22%);
        color: #fff !important;
        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(13.3px);
        border: 1px solid #808080a6;
        padding: 6px 20px !important;
        font-weight: 600 !important;
    }

}

@media (max-width : 992px) {

    .signUpBtnSmScreen {
        background: rgb(0 0 0 / 22%);

        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        color: #fff !important;
        backdrop-filter: blur(13.3px);
        border: 1px solid #808080a6;
        padding: 6px 20px !important;
        font-weight: 600 !important;
    }
}


.navlinkScroll .nav-link {
    color: black;
}

.logo {
    width: 20rem !important;
    height: 4rem !important;
    object-fit: cover;
    margin-bottom: 10px;
}

.navbarHide {
    display: none !important;
}

.logolgScreen {
    display: block;
    height: 4rem !important;
    object-fit: contain;
    margin-bottom: 10px;
    width: 90px;
}

.logoSmScreen {
    display: none;

}


.loginBtnNavbar {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;
    border: 1px solid navy;

    &:hover {
        background: linear-gradient(0deg, #02004F, #02004F), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), #000000;

    }
}


.signUpBtnScroll {
    background: rgb(0 0 0 / 22%);

    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: #fff !important;
    backdrop-filter: blur(13.3px);
    border: 1px solid #808080a6;
    padding: 6px 20px !important;
    font-weight: 600 !important;
}

@media (max-width : 500px) {
    .logo {
        width: 13rem !important;
        height: 4rem !important;
        object-fit: cover;
        margin-bottom: 10px;
    }

    .logoSmScreen {
        width: 90pz !important;
        height: 4rem !important;
        object-fit: contain;
        margin-bottom: 10px;
        margin-left: 1rem;
        display: block
    }

    .navbar-toggler {
        height: 40px !important;
        width: 60px !important;
    }

    .logolgScreen {
        display: none;
    }
}


@media screen and (min-width :500px) and (max-width : 992px) {
    .logolgScreen {
        display: none;
    }

    .logoSmScreen {
        display: block;
        display: block;
        width: 90px !important;
        height: 4rem !important;
        object-fit: contain;
    }

    .collapse .show {
        background-color: white !important;
    }

}

// .videoLibrayBtn{

// background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
// border-radius: 12px;
// color: white;
// font-weight: 500;
// padding: 5px 15px 5px 15px;
// border: none;
// outline: none;
// box-shadow: 1px 2px 3px 2px #1219d347;
// }

.videoLibrayBtn {
    align-items: center;
    appearance: none;
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;

    @media(max-width: 550px) {
        height: 40px;
    }
}

.videoLibrayBtn:focus {
    box-shadow: rgba(0, 79, 207, 0.8) 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(0, 79, 207, 0.8) 0 -3px 0 inset;
}

.videoLibrayBtn:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(0, 79, 207, 0.8) 0 -3px 0 inset;
    transform: translateY(-2px);
}

.videoLibrayBtn:active {
    box-shadow: rgba(0, 79, 207, 0.8) 0 3px 7px inset;
    transform: translateY(2px);
}



/* NAV */

nav#mynav {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    background: transparent;
    transition: .3s all ease;
}

// nav.scr {
//     background-color: #fff;
// }

nav#mynav .wrapper {
    position: relative;
    height: 90px;
    line-height: 48px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
}

.logo {
    width: 90px !important;
}

.wrapper .logo a {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-decoration: none;
}

.wrapper .nav-links {
    display: inline-flex;
    margin-bottom: 0px;
    margin-left: 10%;
    padding-left: 0px;
}

.wrapper .nav-links ul {
    display: flex;
    gap: 20px;
}

.btns-nv {
    display: flex;
    gap: 10px;
    align-items: center;

    a {
        height: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.numb_nav {
    text-align: right;
    margin-left: 20px;
}

.down-an {
    vertical-align: middle;
}

.nav-ic {
    color: #cc9562;
}

.nav-span {
    display: block;
}

.mbbtn {
    display: none;
}

.nav-links li {
    list-style: none;
}

.nav-links li a {
    color: #fff;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    padding: 24px 15px;
    transition: all 0.3s ease;

    @media (min-width: 1800px) {
        font-size: 22px;
    }

    @media (max-width: 992px) {
        font-size: 16px;
    }
}

.nav-links li a:hover {
    color: #03b2cb;
}

.nav-links .mobile-item {
    display: none;
}

.nav-links .drop-menu {
    position: absolute;
    background-color: #f7f7f7;
    max-width: 340px;
    width: auto;
    line-height: 35px;
    top: 90px;
    opacity: 0;
    padding: 20px 20px 10px 0;
    visibility: hidden;

}

.nvbxn {
    width: 200px;
}

.lstdrp {
    right: 0;
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
    transition: all 0.3s ease;
    top: 90px;
    opacity: 1;
    visibility: visible;
}


.drop-menu li a {
    width: 100%;
    display: block;
    padding: 1px 20px;
    color: #4e4e4e;
    background-color: #f7f7f7;
    border-bottom: none !important;
    text-align: left;
}

.drop-menu li a:hover {
    text-decoration: none;
    color: #f51d2a !important;
}

.mega-box {
    position: absolute;
    left: 200px;
    width: 40%;
    padding: 0 30px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
}

.megal {
    width: 65% !important;
    left: 100px;
}

.mega-box .content {
    background: #242526;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 1);
    background-color: white;
}

.mega-box .content .row {
    width: 400px;
    line-height: 30px;
}

.mega-box .content .rowl {
    width: 400px;
    line-height: 30px;
}

.content .row img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content .rowl img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content .row header {
    color: rgba(0, 0, 0, 0.911);
    font-size: 16px;
    font-weight: 500;
}

.content .rowl header {
    color: rgba(0, 0, 0, 0.911);
    ;
    font-size: 16px;
    font-weight: 500;
}

.content .row .mega-links {
    margin-left: -40px;
}

.content .rowl .mega-links {
    margin-left: -40px;
}

.row .mega-links li {
    padding: 0px 0px 0px 20px;
}

.rowl .mega-links li {
    padding: 0px 0px 0px 20px;
}

.row .mega-links li a {
    padding: 0px;
    width: 100%;
    padding: 0px 0px 0px 20px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    font-weight: 600 !important;
    display: block;
}

.rowl .mega-links li a {
    padding: 0px;
    width: 100%;
    padding: 0px 0px 0px 20px;
    color: WHITE;
    font-size: 14px;
    font-weight: 400 !important;
    display: block;
}

.row .mega-links li a:hover {
    color: #329acd;
    background-color: transparent;
}

.rowl .mega-links li a:hover {
    color: #329acd;
    background-color: transparent;
}

.wrapper .btn {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: none;
}

.wrapper .btn.close-btn {
    color: #329acd;
    position: absolute;
    right: 30px;
    top: 10px;
}

.myndl {
    width: 100%;
}

.mygr {
    color: #38b87c;
    font-size: 16px;
    font-weight: 500;
}

.dropdown-item {
    line-height: 26px !important;
}

@media screen and (max-width: 768px) {
    .btns-nv {
        display: none;
    }

    .videoLibrayBtn {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
    }

    .mbbtn {
        display: inline-flex;
        width: fit-content !important;
    }

    .mbbtn a {
        height: 40px !important;
        color: #fff !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-bottom: none !important;
    }

    .wrapper .btn {
        display: block;
        color: #20ad9e;
    }

    .corner-nav {
        width: 50px;
        height: 50px;
        bottom: -23%;
        left: 9%;
    }

    .menu-btn {
        margin-left: 0%;
        color: #329acd !important;
        font-size: 28px !important;
    }

    nav#mynav .wrapper {
        padding: 0px 10px;
        justify-content: space-between;
    }

    .logo {
        width: 70px !important;
    }

    .numb_nav {
        width: 50%;
        text-align: left;
        margin-top: 20px;
    }

    .wrapper .nav-links {
        position: fixed;
        height: 100vh;
        width: 100%;
        max-width: 320px;
        top: 0%;
        left: -100%;
        background: #171717;
        display: block;
        margin-left: 0%;
        padding: 50px 0px 0px;
        line-height: 50px;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
        transition: all 0.3s ease;
        color: #fff;
    }

    .bordbt {
        border-bottom: 2px solid #353535;
    }

    /* custom scroll bar */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #242526;
    }

    ::-webkit-scrollbar-thumb {
        background: #3A3B3C;
    }

    #menu-btn:checked~.nav-links {
        left: 0%;
    }

    #menu-btn:checked~.btn.menu-btn {
        display: none;
    }

    #close-btn:checked~.btn.menu-btn {
        display: block;
    }

    .nav-links li a {
        padding: 3px 20px;
        display: block;
        font-size: 16px;
        border-radius: 0px;
        font-weight: 400;
        transition: .3s all ease;
        border-bottom: 1px solid #c4c4c4;
    }

    .bordbt {
        /* border-bottom: 1px solid #c4c4c4; */
    }

    .let_anc {
        padding-left: 0px !important;
        margin: 20px auto 0px 28%;
    }

    .dntebtn {
        padding: 5px 20px !important;
    }

    .nav-links .drop-menu {
        position: relative;
        opacity: 1;
        top: 0px !important;
        left: 0%;
        visibility: visible;
        padding-left: 0px;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        box-shadow: none;
        transition: all 0.3s ease;
        background-color: transparent;
        border-radius: 0px;
        border-left: 0px;
        padding: 0px;
        border-right: 0px;
        background-color: #fff;
    }

    #showDrop:checked~.drop-menu,
    #showMega:checked~.mega-box {
        max-height: 100%;
        transition: .3s all ease;
        right: 0px;
        top: 0px;
    }

    #showMegal:checked~.mega-box {
        max-height: 100%;
    }

    #showDroploc:checked~.drop-menu {
        max-height: 100%;
    }

    #showDropres:checked~.drop-menu {
        max-height: 100%;
    }

    #showDrop2:checked~.drop-menu {
        max-height: 100%;
        transition: .3s all ease;
        right: 0px;
        top: 0px;
    }

    #showDrop3:checked~.drop-menu {
        max-height: 100%;
        transition: .3s all ease;
        right: 0px;
        top: 0px;
    }

    #showDrop4:checked~.drop-menu {
        max-height: 100%;
        transition: .3s all ease;
        right: 0px;
        top: 0px;
    }

    #showDrop5:checked~.drop-menu {
        max-height: 100%;
        transition: .3s all ease;
        right: 0px;
        top: 0px;
    }


    #showDropcom:checked~.drop-menu,
    #showMegainvest:checked~.mega-box {
        max-height: 100%;
    }

    .nav-links .desktop-item {
        display: none;
    }

    .nav-links .mobile-item {
        display: block;
        color: #4e4e4e;
        font-size: 16px;
        font-weight: 400;
        padding: 5px 10px;
        padding-left: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        border-bottom: 1px solid #c4c4c4;
    }

    /* .nav-links .mobile-item:hover{
      background: #3A3B3C;
    } */
    .drop-menu li {
        margin: 0;
    }

    .drop-menu li a {
        font-size: 16px !important;
        color: #4e4e4e;
        border-bottom: 1px solid #c4c4c4 !important;
        background-color: #ffff;
    }

    .drop_a,
    .drop_b {
        border-radius: 0px;
    }

    .drop-corner {
        display: none;
    }

    .mega-box {
        position: static;
        top: 65px;
        opacity: 1;
        visibility: visible;
        padding: 0 20px;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    .megal {
        width: 100% !important;
    }

    .mega-box .content {
        box-shadow: none;
        flex-direction: column;
        padding: 20px 20px 0 20px;
        background-color: transparent;
        border: 0px solid white;
    }

    .mega-box .content .row {
        width: 100%;
        margin-bottom: 15px;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
    }

    .mega-box .content .row:nth-child(1),
    .mega-box .content .row:nth-child(2) {
        border-top: 0px;
    }

    .content .row .mega-links {
        border-left: 0px;
        padding-left: 15px;
    }

    .row .mega-links li {
        margin: 0;
        color: #f2f2f2;

    }

    .row .mega-links li a {
        color: #f2f2f2;

    }

    .content .row header {
        font-size: 19px;
        color: #f2f2f2;
    }
}

nav#mynav input {
    display: none;
}

.body-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0 30px;
}

.body-text div {
    font-size: 45px;
    font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
  // flex-direction: row-reverse;
}

main {
  padding: 0 !important;
  margin: 0 !important;

}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.ant-picker-input>input {
  color: gray !important;
}

.sideBar_wrapper {
  height: 100vh;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  margin: 0 auto;
  scroll-behavior: smooth;
  overflow: scroll;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url("../../images/bgimgLogin.jpg");
  position: relative;
  padding-bottom: 61px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px;
}

/*=================================== Sidebar =======================================*/
.sidebar {
  // background: rgb(2 89 165);
  // background: linear-gradient(90deg, rgb(1 104 194) 0%, rgb(2 89 165) 100%);
  // /* Created with https://www.css-gradient.com */
  background: #171717;
  border-radius: 0 10px 0 0;
  // background: -webkit-linear-gradient(top, #1B66A7, #0F01A1);
  // background: -moz-linear-gradient(top, #1B66A7, #0F01A1);
  // background: linear-gradient(to bottom, rgb(0 122 228),rgb(7, 111, 201), rgb(3, 54, 99));
  border-right: 1px solid gray;
  color: white;
  height: 100vh;
  // position: absolute;
  // z-index: 999;
  // overflow-y: auto;
  // position: absolute;
  // left: 0;
  // top: 0;
}

.sideBar_wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  // max-width: 4500px;
  margin: 0 auto;
  overflow: hidden;
}

@media (max-width : 600px) {
  .sidebar {
    position: absolute;
    z-index: 99;
  }
}

#chart h4 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 10px 10px 0;
}

.chrtbox .am5exporting-menu.am5exporting-valign-top,
.chrtbox .am5exporting-icon.am5exporting-valign-top,
.chrtbox .am5exporting-list.am5exporting-align-top {
  top: -15px !important;
}

.chrtbox .am5exporting-icon {
  filter: invert(1);
}

//---------------------------------navbar_dashboard-------------------------------
.navbar_dashboard {
  position: absolute;
  // border: 2px solid red;
  z-index: 100;
  right: 0;

}

// @media (max-width : 400px) {
//   .sm_navbar_dashboard{
//     width: 90% !important;
//   }
// }

.sm_navbar_dashboard {
  position: absolute;
  right: 0;
  top: 10px;
  width: 90%;
  height: 50px;
  color: white;
}

.sm_navbar_dashboard h2 {
  text-align: center;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo {
  font-size: 18px;
  line-height: 4rem;
  color: white;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

@media (min-width : 768px) {

  .sideBarLink.active {
    border-left: 4px solid white;
    backdrop-filter: blur(7px) saturate(132%);
    -webkit-backdrop-filter: blur(3px) saturate(132%);
    background-color: rgba(28, 143, 194, 0.2) // border-radius: 12px;


  }
}

@media (max-width : 768px) {

  .sideBarLink.active {
    border-left: 4px solid white;
    background: rgb(45, 51, 89);

  }
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}


// ==================================== dashboard main_child_pages====================================

.main_child_pages {
  height: 100vh;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  margin: 0 auto;
  scroll-behavior: smooth;
  overflow: scroll;
  position: relative;
  // padding-bottom:61px ;
  overflow-x: hidden;
}

.sm_main_child_pages {
  position: absolute;
  right: 0;
  width: calc(100% - 45px) !important;
  overflow: scroll;
  height: 100vh;
  margin-left: 45px !important;

}

.Sidebar_profile {
  position: absolute;
  bottom: 2rem;
  background-color: #02549d;
}

.Sidebar_profile:hover {
  background-color: #0486f8;
  cursor: pointer;
}


.sidebarprofileNameDiv {
  padding-left: 10px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 75%;
}


.sidebarprofileNameDiv h5 {
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: 'sansation';
  margin: 0 !important;
}

.sidebarprofileNameDiv h6 {
  color: white;
  font-family: 'sansation';
  font-size: 0.6rem;

}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle:focus {
  border: 1px solid blue !important;
}


.dropdown-toggle {
  background: transparent !important;
  border: none !important;
  position: relative;
  bottom: 5px;
}

.dropdown-toggle:hover {
  background-color: blue;
  border: blue;
}


//   .main_child_pages::-webkit-scrollbar {
//     display: none;
// }

//   .main_child_pages::-webkit-scrollbar-button {
//     display: none;
// }


.canvas {
  height: 180px !important;
  position: absolute;
  top: 3.5rem;
}

.marketLandscapeGraph {
  height: 90% !important;
  position: absolute;
  top: 1.5rem;
  // width: inherit !important;

}


.chartsIconBg {
  font-size: 2rem;
  background-color: #e5e2e2;
  border-radius: 15px;
  position: relative;
  top: 0.2rem;
  width: 55px;
  text-align: center;
  height: 50px;
  left: 1rem;
  box-shadow: 1px 1px 3px 2px rgb(241 241 241);
}


.chartIconText {
  position: relative;
  left: 1rem;
  top: 0.4rem;
  font-family: 'sansation';
  font-weight: 700;
}



// .sidebar.open::before {
//   content: '';
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity */
//   filter: blur(8px); /* Adjust the blur intensity */
//   z-index: 1;
// }

// .sidebar.open {
//   overflow: hidden;
//   position: relative;
// }
.bg_black {
    background-color: #171717;
    border-radius: 20px;
    padding: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: black gray gray black;
}

.doc_slide {
    color: #fff;

    h3 {
        font-size: 24px;
        font-weight: 700;
        color: #fff;
    }

    h4 {
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        font-family: 'sansation';
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 0px;
    }
}

.addbtnt {
    background: linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5) !important;
    color: #fff;
    border: none;
    outline: none;
    min-width: 40px;
    width: fit-content;
    height: 40px;
    padding: 4px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    font-size: 17px;
}

.snglmemb, .companyGallery {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
}

.snglmemb:not(:nth-last-of-type(1)), .companyGallery:not(:nth-last-of-type(1)) {
    margin-bottom: 15px;
}

.snglmemb label , .companyGallery label{
    color: #484848;
}

.snglmemb img {
    border-radius: 10px;
    width: auto;
    max-height: 150px;
    max-width: 100%;
    margin: auto;
    display: flex;
}


.companyGallery img {
    border-radius: 10px;
    width: auto;
    max-height: 90px;
    max-width: 100%;
    margin: auto;
    display: flex;
}

.snglmemb input.form-control:disabled  , .companyGallery input.form-control:disabled{
    background-color: #fff !important;
}

.snglmemb .txtfld , .companyGallery .txtfld{
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    resize: none !important;
}

.btntm {
    outline: none;
    border: 1px solid #ced4da;
    border-radius: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.btntm img {
    border-radius: 0px;
}

.bldlb {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.flxbximg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0a0a0a;
    background-color: #171717;
    width: 100%;
    padding: 15px;
}

.flxbximg .imgsh {
    width: 80px;
    height: 80px;
    border-radius: 10px;
}

.flxbximg .ant-image-mask {
    background: none !important;
    border-radius: 10px !important;
}

@media(max-width: 991px) {
    .bg_black {
        padding: 20px;
    }
}

.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_h1.jodit-toolbar-button_text-icons_true.jodit-ui-group__h1{
    display: none !important;
}

.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_h2.jodit-toolbar-button_text-icons_true.jodit-ui-group__h2{
    display: none !important;
}

.faqHtmlData , .faqHtmlData p {
    color: black !important;
}
.Slider-main {
    background-color: $primary-color;
    width: 100%;
}

.Slider-main h5 {
    color: $black-color;
    font-size: 1.5rem;
}

.Card_company_img {
    z-index: 10;

    &:hover {
        transform: scale(1.01);
    }
}

.card_absolute {
    background-color: rgba(0, 0, 0, 0.356);
    margin-top: -3rem;
    padding: 1px auto;
    min-width: 80px;
    text-align: center;
    margin-bottom: 0;
    z-index: 1;
}

.investor {

    .slick-prev:before,
    .slick-next:before {
        color: rgb(207, 207, 197) !important;
        font-size: 2rem !important;
    }

}
.video {

  &_card {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: white;
    box-shadow: 0 8px 8px -4px rgb(0, 7, 61);

    border-radius: 8px;

    width: 25%;
    // border: 1px solid rgb(0, 7, 61);
  }

  &_header {
    padding: 10px;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 7px;
    text-decoration-thickness: 2px;
    text-decoration-color: rgb(0, 7, 61);
  }


  &_footer {
    padding: 10px;
  }



}

.video_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  row-gap: 2rem;
}

.video_card iframe {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 0;
}

.my-modal {
  height: 90% !important;
  min-width: 80% !important;
}


@media (max-width:700px) {
  .video_card {
    width: 90%;
  }
}

// @media  (max-width:500px){
//   .video_card{
//     width: 70%;
//   }
// }

@media (min-width:700px) {
  .video_card {
    width: 40%;
  }
}

@media (min-width:1100px) {
  .video_card {
    width: 30%;
  }
}



@media (min-width : 2700px) and (max-width : 4000px) {

  .presentation_dashboard {
    // width: 70%;
    margin: 0 auto;
  }
}

@media (min-width : 1700px) and (max-width : 2700px) {

  .presentation_dashboard {
    // width: 60%;
    margin: 0 auto;
  }
}

@media (min-width : 1000px) and (max-width : 1700px) {

  .presentation_dashboard {
    margin: 0 auto;
  }
}

.presentation_dashboard {
  max-width: 1400px;
}